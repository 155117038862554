import React, { useContext } from 'react';
import Link, { propTypes } from '../../components/01_atoms/Link';
import { SiteSettingsContext } from '../../utils/context';

const CustomLink = (props) => {
  const settings = useContext(SiteSettingsContext);
  const { href, as } = props;
  let newHref = href;

  // TODO: remove it when Gifts website starts support CNET format of links.
  if (as) {
    newHref = as;
  }

  let newAs = newHref;

  if (settings) {
    const redirect = settings.redirects.find((item) => item.fromUrl === newHref);
    if (redirect) {
      newHref = redirect.toUrl;
      newAs = redirect.toUrl;
    }

    if (newHref === '/' || newHref === settings.frontPage.homepageLink.url) {
      newHref = '/gifts/home_page';
      newAs = '/';
    } else if (newHref === '/basket') {
      newHref = '/gifts/basket';
    } else if (newHref === '/cards') {
      newHref = '/gifts/cards';
    } else if (newHref === '/checkout') {
      newHref = '/gifts/checkout';
    } else if (newHref.startsWith('/gifts/')) {
      newHref = '/gifts/product';
    } else if (newHref.startsWith('/corporate/')) {
      newHref = '/gifts/corporate_product';
    } else if (newHref.startsWith('/category/')) {
      newHref = '/gifts/category';
    } else {
      newHref = '/gifts/gifts_page';
    }
  }

  return <Link {...props} href={newHref} as={newAs} />;
};

CustomLink.propTypes = propTypes;

export { propTypes };

export default CustomLink;
