import React from 'react';
import * as PropTypes from 'prop-types';

import s from './index.module.scss';
import SantaClausSignatureIcon from '../../../public/static/Gifts/images/santa-claus-signature.svg';

const SantaLetterTemplate = ({ name = '', present = '', postScriptum = '' }) => (
  <div className={s['bb-santa-letter__letter-template']}>
    <div className={s['bb-santa-letter__letter-text']}>
      <p>
        Dear{' '}
        <span className={s['bb-santa-letter__letter-value']}>{name ? name : '(your child\'s name)'}</span>
      </p>
      <p>
        Snow is falling, mince pies are baking, and sleigh bells are jingling. There’s magic in the
        air at the North Pole (even more than usual), and that can only mean one thing. Christmas is
        almost here!
      </p>
      <p>
        Between you, me, and the reindeer, you’re right at the top of
        <br />
        my ‘Nice’ list. Your name is shining like a star in big, gold, sparkly letters. Because
        you’ve been so kind, thoughtful, and caring this year, the elves are working extra hard on
        your present.
      </p>
      <p>
        My favourite part of Christmas is leaving all the special treats and toys out for lovely
        girls and boys like you. That, and eating as many mince pies as I can get my gloves on.
      </p>
      <p>
        Speaking of gifts, there’s{' '}
        <span className={s['bb-santa-letter__letter-value']}>
          {present ? present : '(something super special)'}
        </span>{' '}
        in my workshop for you. It’s almost finished. And with my elves’ help, I’m putting the final
        sprinkles of magic on it for you.
      </p>
      <p>
        Make sure you get a good night’s sleep on Christmas Eve, and don’t forget to leave a carrot
        out for Rudolph. I hope the sound
        <br />
        of sleigh bells ringing doesn’t wake you up!
      </p>
      <p>Have a magical Christmas,</p>
      <SantaClausSignatureIcon className={s['bb-santa-letter__letter-signature']} />
      <p>
        P.S.{' '}
        <span className={s['bb-santa-letter__letter-value']}>
          {postScriptum
            ? postScriptum
            : '(Add a personalised message for your child)'}
        </span>
      </p>
    </div>
  </div>
);

SantaLetterTemplate.propTypes = {
  name: PropTypes.string,
  present: PropTypes.string,
  postScriptum: PropTypes.string,
};

export default SantaLetterTemplate;
