import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { TextArea, TextField } from '../../../components/01_atoms/FormElements';
import GiftsForm from '../../Form';
import { required } from '../../../utils/formFieldValidators';
import Button from '../../Button';
import { pushSantaLetterFormValidationErrors as gtmPushSantaLetterFormValidationErrors } from '../../../utils/gtm';
// We have to use the original component instead of /components/01_atoms/Tooltip
// because of complex behaviour;
import { Tooltip } from 'react-tooltip';

import s from './index.module.scss';
import ts from '../../../components/01_atoms/Tooltip/index.module.scss';

// The form is used in two instances, the one for desktop and another one in modal (for mobiles).
// Desktop form should update values in real time. The Modal form should update values on submit.
const SantaLetterForm = ({
  onSubmit,
  initialValues,
  onChange,
  onFocusChange = () => {},
  isModal = false,
  isFilled = false,
}) => {
  const [submitTooltipOpen, setSubmitTooltipOpen] = useState(false);

  return (
    <GiftsForm>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        subscription={{ values: true, valid: true, errors: true }}
        render={({ handleSubmit, form, valid, errors }) => {
          // Used for desktop form only.
          const handleChange = () => {
            const values = form.getState().values;
            onChange(values);
            setSubmitTooltipOpen(false);
          };

          // Disable submit button and show tooltip if none of the fields are filled.
          // Otherwise, a standard validation will be fired.
          const isDisabled = Object.keys(errors).length > 2;
          const showTooltip = (e) => {
            e.preventDefault();
            setSubmitTooltipOpen(true);
          };

          return (
            <form onSubmit={handleSubmit} onChange={handleChange}>
              <TextField
                name={isModal ? 'name_modal' : 'name'}
                label="What's your child's name?"
                info="Works best with one child per letter."
                validate={required("Please enter your child's name")}
                isRequired
                placeholder="Ronan"
                maxLength={30}
                onFocus={() => onFocusChange('name')}
                onBlur={() => onFocusChange(null)}
              />
              <TextField
                name={isModal ? 'present_modal' : 'present'}
                label="What present would they like?"
                info="Use 48 characters max."
                validate={required('This field is mandatory')}
                isRequired
                placeholder="a shiny blue scooter"
                maxLength={48}
                onFocus={() => onFocusChange('present')}
                onBlur={() => onFocusChange(null)}
              />
              <TextArea
                name={isModal ? 'post_scriptum_modal' : 'post_scriptum'}
                label="What amazing things have they accomplished that Santa can highlight?"
                info="This will be placed inside of a ”PS” section at the bottom of the letter.<br>Use 120 characters max."
                validate={required('This field is mandatory')}
                isRequired
                placeholder="You have been the best big brother and so brave going to school. Your parents are proud of you and so am I!"
                maxLength={120}
                onFocus={() => onFocusChange('post-scriptum')}
                onBlur={() => onFocusChange(null)}
              />

              <div className={s[`bb-santa-letter__form-submit${isModal ? '--modal' : '--normal'}`]}>
                <Button
                  buttonType="submit"
                  type={isModal ? 'dark' : 'xmas'}
                  onClick={(e) => {
                    // Push GTM validation errors.
                    if (Object.keys(errors).length) {
                      gtmPushSantaLetterFormValidationErrors(errors);
                    }

                    if (isModal && isDisabled) {
                      showTooltip(e);
                    } else {
                      handleSubmit(e);
                    }
                  }}
                  className={`${s['bb-santa-letter__form-submit-button']} ${!valid ? s['bb-santa-letter__form-submit-button--disabled'] : ''}`}
                  id="santa-letter-form-submit-button"
                >
                  {!isModal ? 'Add to your basket' : isFilled ? 'Update letter' : 'Add to letter'}
                </Button>
                {/* Not a standard way of using tooltip, but only this way desired behaviour can be achieved. */}
                <div className={`${s['bb-santa-letter__form-submit-tooltip']} ${ts['tooltip']}`}>
                  <Tooltip
                    anchorSelect="#santa-letter-form-submit-button"
                    content="Please, complete all the fields to proceed"
                    isOpen={submitTooltipOpen}
                    hidden={!submitTooltipOpen}
                  />
                </div>
              </div>
            </form>
          );
        }}
      />
    </GiftsForm>
  );
};

SantaLetterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func,
  isModal: PropTypes.bool,
  isFilled: PropTypes.bool,
};

export default SantaLetterForm;
