import { string, number, bool, shape, object, oneOfType } from 'prop-types';

export const PTHubResultCard = {
  type: string,
  resourceType: string,
  title: string,
  published: number,
  description: string,
  image: shape({
    url: string,
    alt: string,
  }),
  link: shape({
    url: oneOfType([string, object]),
    target: string,
    host: string,
  }),
  featured: bool,
  variant: string,
};
