import React from 'react';
import PropTypes from 'prop-types';
import CookieConsentDialog from '../CookieConsentDialog';

/* global Cookiebot */
const withCookieConsentPopupVideo = (VideoBlock) => {
  class VideoBlockWithCookieConsent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isCookiebotLoaded: false,
        isComponentVisible: false,
      };

      this.onCookiebotLoad = this.onCookiebotLoad.bind(this);
      this.onAcceptCookie = this.onAcceptCookie.bind(this);
    }

    componentDidMount() {
      const { cookieConsentType } = this.props;
      if (cookieConsentType) {
        window.addEventListener('CookiebotOnConsentReady', this.onCookiebotLoad);
      }

      // In case when Cookibot already loaded we need to switch component to
      // the right state. For example when we open popup with embedded video.
      if (typeof Cookiebot !== 'undefined') {
        this.onCookiebotLoad();
      }
    }

    componentWillUnmount() {
      const { cookieConsentType } = this.props;
      if (cookieConsentType) {
        window.removeEventListener('CookiebotOnConsentReady', this.onCookiebotLoad);
      }
    }

    onCookiebotLoad() {
      const { cookieConsentType } = this.props;

      if (Cookiebot && Cookiebot.consent && Cookiebot.consent[cookieConsentType]) {
        this.setState({
          isCookiebotLoaded: true,
          isComponentVisible: true,
        });
      } else {
        this.setState({
          isCookiebotLoaded: true,
          isComponentVisible: false,
        });
      }
    }

    onAcceptCookie(preferences, statistics, marketing) {
      const { isExample } = this.props;
      if (isExample) {
        this.setState({ isCookiebotLoaded: true, isComponentVisible: true });
      } else {
        Cookiebot.submitCustomConsent(preferences, statistics, marketing);
      }
    }

    render() {
      const {
        isExample,
        cookieConsentType,
        cookieDialogHeading,
        cookieDialogDescription,
        onCancelDialog,
        acceptCookiesButtonClassName,
      } = this.props;
      const { isCookiebotLoaded, isComponentVisible } = this.state;

      // We expect that cookieConsentType will be always defined. Otherwise
      // we think that component doesn't expect certain level of cookies, so
      // we can render component as is.
      if (!cookieConsentType && !isExample) {
        return <VideoBlock {...this.props} />;
      }

      let preferences;
      let statistics;
      let marketing;

      if (isCookiebotLoaded || isExample) {
        if (isComponentVisible) {
          return <VideoBlock {...this.props} />;
        }

        if (!isExample) {
          preferences = cookieConsentType === 'preferences';
          statistics = cookieConsentType === 'statistics';
          marketing = cookieConsentType === 'marketing';

          if (Cookiebot && Cookiebot.consent) {
            preferences = preferences || Cookiebot.consent.preferences;
            statistics = statistics || Cookiebot.consent.statistics;
            marketing = marketing || Cookiebot.consent.marketing;
          }
        }
      }

      const title =
        cookieDialogHeading || `Please accept ${cookieConsentType} cookies to view this content.`;

      return (
        <div className="container">
          <div className="poster">
            <CookieConsentDialog
              className="cookie-consent-context-dialog"
              logo={{ url: '/static/icons/social/youtube.svg', alt: 'youtube logo' }}
              title={title}
              description={cookieDialogDescription}
              onAccept={() => this.onAcceptCookie(preferences, statistics, marketing)}
              onDecline={onCancelDialog}
              buttonClassName={acceptCookiesButtonClassName}
            />
          </div>
        </div>
      );
    }
  }

  VideoBlockWithCookieConsent.propTypes = {
    // isExample param can be used in case when we have to gain
    // the same behaviour of the component without Cookiebot.
    // Already used on the Body Blocks page in styleguide.
    isExample: PropTypes.bool,
    cookieConsentType: PropTypes.string,
    cookieDialogHeading: PropTypes.string,
    cookieDialogDescription: PropTypes.string,
    videoURL: PropTypes.string.isRequired,
    onCancelDialog: PropTypes.func.isRequired,
    acceptCookiesButtonClassName: PropTypes.string,
  };

  VideoBlockWithCookieConsent.defaultProps = {
    isExample: false,
    cookieConsentType: '',
    cookieDialogHeading: '',
    cookieDialogDescription: '',
    acceptCookiesButtonClassName: '',
  };

  return VideoBlockWithCookieConsent;
};

export default withCookieConsentPopupVideo;
