import React from 'react';
import PropTypes from 'prop-types';
import HeroBackground from '../HeroBackground';
import { propTypes as defaultPicturePropTypes } from '../../../../01_atoms/DefaultPicture';
import FormattedText from '../../../../01_atoms/FormattedText';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBActivismHero = ({
  imagePicture,
  videoURL = null,
  title,
  label = '',
  alignment = 'center',
  size = 'default',
  behaviorSettings = null,
  uuid = null,
}) => {
  const classes = [
    styles['bb-activism-hero'],
    styles[`bb-activism-hero--alignment-${alignment}`],
    styles[`bb-activism-hero--size-${size}`],
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];

  return (
    <HeroBackground
      uuid={uuid}
      className={classes.join(' ')}
      imagePicture={imagePicture}
      videoURL={videoURL}
    >
      <div className={styles['bb-activism-hero__container-wrapper']}>
        <div className="container">
          <div className={styles['bb-activism-hero__content']}>
            {label && <p className={styles['bb-activism-hero__label']}>{label}</p>}
            <FormattedText className={styles['bb-activism-hero__title']} text={title} tag="h1" />
          </div>
        </div>
      </div>
    </HeroBackground>
  );
};

BBActivismHero.propTypes = {
  imagePicture: PropTypes.shape(defaultPicturePropTypes).isRequired,
  videoURL: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  alignment: PropTypes.oneOf(['left', 'center']),
  size: PropTypes.oneOf(['default', 'thin']),
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBActivismHero;
