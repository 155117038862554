import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import * as breakpoints from '../../../../../utils/breakpoint';
import FakeSearchInput from '../../../../01_atoms/SearchElements/FakeSearchInput';
import SearchInput from '../../../../01_atoms/SearchElements/SearchInput';
import SearchInputMobilePopup from '../../../../01_atoms/SearchElements/SearchInputMobilePopup';
import focusAndOpenKeyboard from '../../../../../utils/focusAndOpenKeyboard';

const SearchInputComponent = ({ filteredValue = '', submitSearchValue, inputPlaceholder = '' }) => {
  const [value, setSearchValue] = useState(
    filteredValue ? filteredValue.substr(1, filteredValue.length - 2) : '',
  );
  const [isSearchVisible, setSearchVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const inputId = 'knowledge-hub-search-input';

  // Reset the field value when user cleared all filters.
  useEffect(() => {
    if (!filteredValue) {
      setSearchValue('');
    }
  }, [filteredValue]);

  useEffect(() => {
    function handleResize() {
      if (breakpoints.isUp('md') && isMobile) {
        setIsMobile(false);
      }
      if (breakpoints.isDown('md') && !isMobile) {
        setIsMobile(true);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  // Make search visible in case of first render when URL contains search filter.
  if (value && !isSearchVisible && !isMobile) {
    setSearchVisible(true);
  }

  const onFakeSearchClick = (e) => {
    setSearchVisible(true);
    focusAndOpenKeyboard(inputId);
  };

  return (
    <div>
      {!isMobile && (
        <div className={styles['desktop']}>
          <div className={styles['knowledge-hub-search-wrapper']}>
            <SearchInput
              submitSearchValue={submitSearchValue}
              setValue={setSearchValue}
              value={value}
              inputPlaceholder={inputPlaceholder}
              inputId={inputId}
            />
          </div>
        </div>
      )}
      {isMobile && (
        <div className={styles['mobile']}>
          {!isSearchVisible && (
            <FakeSearchInput
              value={filteredValue ? filteredValue.substr(1, filteredValue.length - 2) : ''}
              onClick={onFakeSearchClick}
              placeholder={inputPlaceholder}
              onClear={() => {
                setSearchValue('');
                submitSearchValue('');
              }}
            />
          )}

          {isSearchVisible && (
            <SearchInputMobilePopup
              inputPlaceholder={inputPlaceholder}
              submitSearchValue={submitSearchValue}
              setSearchValue={setSearchValue}
              value={value}
              suggestedSearches={null}
              onClose={() => setSearchVisible(false)}
              inputId={inputId}
            />
          )}
        </div>
      )}
    </div>
  );
};

SearchInputComponent.propTypes = {
  filteredValue: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  submitSearchValue: PropTypes.func.isRequired,
};

export default SearchInputComponent;
