import React from 'react';
import PropTypes from 'prop-types';
import { propTypes as linkPropTypes } from '../../../../01_atoms/Link';
import { LinkTitle } from '../../../../02_molecules/Link';
import ResponsiveHeroImage, { propTypes as imagePropTypes } from '../index';
import FormattedText from '../../../../01_atoms/FormattedText';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

import baseStyles from '../index.module.scss';
import styles from './index.module.scss';

const BBHeroLanding = ({
  title,
  description,
  image,
  cta = null,
  behaviorSettings = null,
  uuid = null,
}) => (
  <div
    className={`bb bb-hero ${baseStyles['bb-hero']} bb-hero-landing ${
      styles['bb-hero-landing']
    } ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    title={image && image.medium && image.medium.alt ? image.medium.alt : null}
    id={uuid}
  >
    <ResponsiveHeroImage image={image} />

    <div className="gradient" />

    <div className="container-wrapper">
      <div className="container">
        <div className="row">
          <div className="overlay col-12 col-lg-10 col-xl-8">
            {/* We use empty title to overwrite title for the block. */}
            <h1 className="header-two rockitt" title="">
              {title}
            </h1>

            {/* We use empty title to overwrite title for the block. */}
            <div title="">
              {description && (
                <FormattedText className="d-none d-md-block description" text={description} />
              )}

              {cta && cta.label && cta.nextLink && (
                <LinkTitle label={cta.label} url={cta.nextLink.url} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

BBHeroLanding.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape(imagePropTypes).isRequired,
  cta: PropTypes.shape({
    nextLink: PropTypes.shape(linkPropTypes),
    label: PropTypes.string,
  }),
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBHeroLanding;
