import React from 'react';
import { ChildrenProps, DefaultPicture as DefaultPictureProps } from '../../../../types';
import styles from './index.module.scss';
import DefaultPicture from '../../../01_atoms/DefaultPicture';
import LoopingVideo from '../../../01_atoms/LoopingVideo';

type Props = {
  uuid?: string;
  imagePicture: DefaultPictureProps;
  videoURL?: string;
  className?: string;
  children?: ChildrenProps;
};

export const HeroBackground = ({
  imagePicture,
  videoURL = '',
  uuid,
  className = '',
  children,
}: Props) => (
  <div
    className={`bb bb-hero ${styles['bb-hero']} ${videoURL ? ' bb-hero--video' : ''} ${className}`}
    title={imagePicture.alt && !videoURL ? imagePicture.alt : undefined}
    id={uuid}
  >
    <div className="view-box-wrapper">
      <div className="image-box">
        <DefaultPicture
          className={`responsive-image ${videoURL ? 'd-md-none' : ''}`}
          {...imagePicture}
        />

        {videoURL && <LoopingVideo src={videoURL} />}
      </div>
    </div>
    {children}
  </div>
);

export default HeroBackground;
