import React from 'react';
import PropTypes from 'prop-types';
import HeroBackground from '../HeroBackground';
import { propTypes as defaultPicturePropTypes } from '../../../../01_atoms/DefaultPicture';
import { propTypes as linkPropTypes } from '../../../../01_atoms/Link';
import FormattedText from '../../../../01_atoms/FormattedText';
import { LinkChevron } from '../../../../02_molecules/Link';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBHeroWhiteFontStrapline = ({
  imagePicture,
  videoURL = null,
  text,
  link = null,
  behaviorSettings = null,
  uuid = null,
}) => (
  <HeroBackground
    uuid={uuid}
    className={`bb-hero-white-font-strapline ${
      styles['bb-hero-white-font-strapline']
    } ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    imagePicture={imagePicture}
    videoURL={videoURL}
  >
    <div className="container-wrapper">
      <div className="container">
        <div className="bb-hero-white-font-strapline--animated-text">
          <FormattedText text={text} tag="h1" />
          {link && link.nextLink && link.nextLink.url && <LinkChevron {...link} isBold />}
        </div>
      </div>
    </div>
  </HeroBackground>
);

BBHeroWhiteFontStrapline.propTypes = {
  imagePicture: PropTypes.shape(defaultPicturePropTypes).isRequired,
  videoURL: PropTypes.string,
  text: PropTypes.string.isRequired,
  link: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape(linkPropTypes),
  }),
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBHeroWhiteFontStrapline;
