import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';
import { TagText } from '../../../01_atoms/Tag';
import { LinkChevron } from '../../../02_molecules/Link';
import { formattedDate } from '../../../../utils/date-formatter.js';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBDetailMeta = ({
  type = null,
  timestamp,
  author = null,
  button = null,
  centeredLayoutMode = false,
  behaviorSettings = null,
  uuid = null,
}) => (
  <div
    className={`bb ${styles['bb-detail-meta']} ${generateClassNameByBehaviorSettings(
      behaviorSettings,
    )}`}
    id={uuid}
  >
    <div className="container">
      <div className={centeredLayoutMode ? 'row' : ''}>
        <div
          className={centeredLayoutMode ? 'col-12 col-md-11 offset-md-1 centered-layout-mode' : ''}
        >
          <div className="meta">
            {type && <TagText label={type} />}

            {timestamp && (
              <span className="date">
                <mark>
                  <strong>{formattedDate(timestamp)}</strong>
                </mark>
              </span>
            )}

            {author && (
              <span className="author">
                <LinkChevron label={author.name} nextLink={author.nextLink} size="small" />
              </span>
            )}

            <span className="separator-v" />
          </div>

          {button && button.label && button.nextLink && (
            <div className="button-container d-none d-md-block">
              <Link {...button.nextLink}>
                <Button
                  type="secondary"
                  isOutlined
                  tag="a"
                  href={button.nextLink.url}
                  size="small"
                  className="bb-detail-meta--button"
                >
                  {button.label}
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

BBDetailMeta.propTypes = {
  type: PropTypes.string,
  timestamp: PropTypes.number.isRequired,
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    nextLink: PropTypes.shape(linkPropTypes).isRequired,
  }),
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    nextLink: PropTypes.shape(linkPropTypes).isRequired,
  }),
  // Workaround to make look of this block match design for centered layout
  // on certain pages.
  centeredLayoutMode: PropTypes.bool,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBDetailMeta;
