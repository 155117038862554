import React from 'react';
import PropTypes from 'prop-types';
import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import DefaultPicture, { propTypes as picturePropTypes } from '../../../01_atoms/DefaultPicture';

import RightThickChevron from '../../../../public/static/icons/navigation-chevron-right-white-thick.svg';

import styles from './index.module.scss';

const ItemWithImageHoverableSmall = ({ title = null, description = null, image, nextLink }) => (
  <div className={`item-with-image-hoverable-small ${styles['item-with-image-hoverable-small']}`}>
    <Link {...nextLink}>
      <a className="item">
        {image && <DefaultPicture loading="lazy" {...image} />}

        <div className="hoverable">
          {title && <h3 className="title">{title}</h3>}

          {description && <p className="description">{description}</p>}

          {title && (
            <span className="more-info-link">
              More on {title} <RightThickChevron className="link-title-chevron" />
            </span>
          )}

          <div className="hoverable-bg" />
        </div>
      </a>
    </Link>
  </div>
);

ItemWithImageHoverableSmall.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape(picturePropTypes).isRequired,
  nextLink: PropTypes.shape(linkPropTypes).isRequired,
};

export default ItemWithImageHoverableSmall;
