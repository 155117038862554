import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormSpy } from 'react-final-form';

import { SelectFieldStandalone } from '../../01_atoms/FormElements';
import Spinner from '../../01_atoms/Spinner';
import withIE, { IEContext } from '../../05_hoc/withIE';

import ClearIcon from '../../../public/static/icons/close-header-main.svg';
import s from './index.module.scss';

const DropdownFiltersForm = ({ title = '', isClearable = false, selects, onSubmit = () => {} }) => (
  <IEContext.Consumer>
    {({ isIE }) => (
      <div
        className={`bb ${s['dropdown-filters-form']} ${isIE ? s['dropdown-filters-form__ie11-compat'] : ''}`}
      >
        <div className="container">
          {title && <h2 className="rockitt">{title}</h2>}

          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting, form, values }) => (
              <form onSubmit={handleSubmit}>
                <FormSpy onChange={form.submit} subscription={{ values: true }} />

                {selects.map((select) => (
                  <SelectFieldStandalone key={select.name} {...select} isDisabled={submitting} />
                ))}

                {isClearable && values && Object.keys(values).length > 0 && !submitting && (
                  <div
                    onClick={form.reset}
                    onKeyPress={form.reset}
                    className={s['dropdown-filters-form__clear']}
                  >
                    Clear Filters <ClearIcon />
                  </div>
                )}

                {submitting && <Spinner />}
              </form>
            )}
          />
        </div>
      </div>
    )}
  </IEContext.Consumer>
);

export const PTDropdownFiltersForm = {
  title: PropTypes.string,
  isClearable: PropTypes.bool,
  onSubmit: PropTypes.func,
  selects: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

DropdownFiltersForm.propTypes = PTDropdownFiltersForm;

export default withIE(DropdownFiltersForm);
