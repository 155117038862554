import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import DefaultPicture, { propTypes as picturePropTypes } from '../../../01_atoms/DefaultPicture';
import Button from '../../../01_atoms/Button';
import TextButton from '../../../01_atoms/TextButton';
import BrandedBadge from '../../../01_atoms/BrandedBadge';
import {
  generateClassNameByBehaviorSettings,
  behaviorSettingsProps,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBTwoColumnAppeals = ({
  title = '',
  featuredItem,
  items,
  variant = 'default',
  behaviorSettings = null,
  uuid = null,
}) => {
  const isTextButton = (type) => type === 'text';

  return (
    <div
      className={`bb ${styles.block} variant-${variant} ${generateClassNameByBehaviorSettings(
        behaviorSettings,
      )}`}
      id={uuid}
    >
      <div className="container">
        {title && <h2 className={styles.heading}>{title}</h2>}
        <ul className={styles.ul}>
          <li className={styles.featured}>
            <Link {...featuredItem.CTA.nextLink}>
              <a
                href={featuredItem.CTA.nextLink.url}
                data-title={featuredItem.CTA.nextLink.title ?? featuredItem.title}
                data-category="Two column appeals block"
                data-position="0"
                data-variant="featured"
                data-button-type={featuredItem.CTA.type}
              >
                <div className={styles.image}>
                  <DefaultPicture {...featuredItem.image} />
                  {featuredItem.badge && (
                    <BrandedBadge label={featuredItem.badge} className={styles.badge} />
                  )}
                </div>

                <div className={styles.info}>
                  <div className={styles.title}>{featuredItem.title}</div>
                  {isTextButton(featuredItem.CTA.type) ? (
                    <TextButton withArrow tabIndex="-1">
                      {featuredItem.CTA.label}
                    </TextButton>
                  ) : (
                    <Button
                      tag="button"
                      type={featuredItem.CTA.type}
                      size="large"
                      withArrow
                      tabIndex="-1"
                    >
                      {featuredItem.CTA.label}
                    </Button>
                  )}
                </div>
              </a>
            </Link>
          </li>
          {items.map((item, index) => (
            <li className={styles.normal} key={item.title}>
              <Link {...item.CTA.nextLink}>
                <a
                  href={item.CTA.nextLink.url}
                  data-title={item.CTA.nextLink.title ?? item.title}
                  data-category="Two column appeals block"
                  data-position={index + 1}
                  data-variant="normal"
                  data-button-type={item.CTA.type}
                >
                  <div className={styles.image}>
                    <DefaultPicture {...item.image} />
                  </div>

                  <div className={styles.info}>
                    <div className={styles.title}>{item.title}</div>
                    {isTextButton(item.CTA.type) ? (
                      <TextButton withArrow tabIndex="-1">
                        {item.CTA.label}
                      </TextButton>
                    ) : (
                      <Button
                        tag="button"
                        type={item.CTA.type}
                        size="large"
                        withArrow
                        tabIndex="-1"
                      >
                        {item.CTA.label}
                      </Button>
                    )}
                  </div>
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

BBTwoColumnAppeals.propTypes = {
  title: PropTypes.string,
  featuredItem: PropTypes.shape({
    title: PropTypes.string.isRequired,
    badge: PropTypes.string,
    image: PropTypes.shape(picturePropTypes).isRequired,
    CTA: PropTypes.shape({
      label: PropTypes.string.isRequired,
      nextLink: PropTypes.shape(linkPropTypes).isRequired,
      type: PropTypes.PropTypes.oneOf(['primary', 'secondary', 'text']).isRequired,
    }).isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.shape(picturePropTypes).isRequired,
      CTA: PropTypes.shape({
        label: PropTypes.string.isRequired,
        nextLink: PropTypes.shape(linkPropTypes).isRequired,
        type: PropTypes.oneOf(['primary', 'secondary', 'text']).isRequired,
      }).isRequired,
    }),
  ).isRequired,
  variant: PropTypes.oneOf(['default', 'activism']),
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBTwoColumnAppeals;
