import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../01_atoms/Link';
import Button from '../../01_atoms/Button';
import DefaultPicture, { propTypes as picturePropTypes } from '../../01_atoms/DefaultPicture';
import TextButton from '../../01_atoms/TextButton';

import styles from './index.module.scss';

const TileItemActivism = ({ title, image, primaryCTA, className = '', position }) => (
  <li className={`activism-tile-item ${styles['activism-tile-item']} ${className}`}>
    <Link {...primaryCTA.nextLink}>
      <a
        href={primaryCTA.nextLink.url}
        className="activism-tile-item__container"
        data-title={primaryCTA.nextLink.title ? primaryCTA.nextLink.title : title}
        data-category="Three tile block"
        data-position={position}
        data-variant="Activism"
        data-button-type={primaryCTA.type}
      >
        <div className="activism-tile-item__image">
          <DefaultPicture {...image} />
        </div>

        <div className="activism-tile-item__info">
          <div className="activism-tile-item__title">{title}</div>
          {primaryCTA.type === 'primary' ? (
            <Button tag="button" type={primaryCTA.type} size="large" withArrow tabIndex="-1">
              {primaryCTA.label}
            </Button>
          ) : (
            <TextButton type="light" withArrow tabIndex="-1">
              {primaryCTA.label}
            </TextButton>
          )}
        </div>
      </a>
    </Link>
  </li>
);

const propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape(picturePropTypes).isRequired,
  primaryCTA: PropTypes.shape({
    label: PropTypes.string.isRequired,
    nextLink: PropTypes.shape(linkPropTypes).isRequired,
    type: PropTypes.oneOf(['primary', 'text']).isRequired,
  }).isRequired,
  className: PropTypes.string,
};

TileItemActivism.propTypes = {
  ...propTypes,
  position: PropTypes.number.isRequired,
};

export { propTypes };

export default TileItemActivism;
