import React from 'react';
import PropTypes from 'prop-types';
import TrackVisibility from 'react-on-screen';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

import styles from './index.module.scss';
import headingStyles from '../BBHeading/index.module.scss';

/**
 * 'Headline with animated highlight' body block in Drupal.
 */
const BBHeadingWithAnimation = ({
  Tag = 'h2',
  title,
  style = {},
  classNameWrapper = '',
  behaviorSettings = null,
  uuid = null,
}) => {
  const classes = [
    'bb',
    styles['bb-heading-with-animation'],
    classNameWrapper,
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];

  return (
    <TrackVisibility once>
      {({ isVisible }) => (
        <div
          className={`${classes.join(' ')} ${
            isVisible ? styles['bb-heading-with-animation--highlight'] : ''
          }`}
          id={uuid}
        >
          <div className="container">
            <Tag
              style={{ ...style }}
              className={`${headingStyles['bb-heading']} ${Tag} ${styles['bb-heading-with-animation__element']}`}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        </div>
      )}
    </TrackVisibility>
  );
};

BBHeadingWithAnimation.propTypes = {
  Tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  /**
   * Html string. Parts of the title wrapped in `<strong>`strong`</string>` will be animated.
   */
  title: PropTypes.string.isRequired,
  style: PropTypes.shape(),
  classNameWrapper: PropTypes.string,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBHeadingWithAnimation;
