import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import Picture from '../Picture';

const DEFAULT_PRESETS = [
  {
    name: 'small',
    className: 'responsive-image responsive-image-mobile',
    query: '(max-width: 420px)',
    width: 420,
    height: 280,
  },
  {
    name: 'medium',
    className: 'responsive-image responsive-image-tablet',
    query: '(min-width: 421px) and (max-width: 992px)',
    width: 992,
    height: 558,
  },
  {
    name: 'large',
    className: 'responsive-image responsive-image-desktop',
    query: '(min-width: 993px)',
    width: 1400,
    height: 550,
  },
];

const ResponsiveImage = ({ image, presets = DEFAULT_PRESETS, loading = null }) => {
  // Filter out presets that were not passed in props.
  presets.filter((preset) => image[preset.name] && image[preset.name].url);

  if (loading === 'preload') {
    return (
      <>
        {presets.map((preset) => (
          <React.Fragment key={preset.name}>
            <Head>
              <link rel="preload" href={image[preset.name].url} as="image" media={preset.query} />
            </Head>
            <Picture
              className={preset.className}
              url={image[preset.name].url}
              media={preset.query}
              fallbackSrc={image[preset.name].url}
              alt={image[preset.name].alt}
              title={image[preset.name].alt}
              width={preset.width}
              height={preset.height}
              attrWidth={preset.width}
              attrHeight={preset.height}
              loading="eager"
            />
          </React.Fragment>
        ))}
      </>
    );
  }

  return (
    <>
      {presets.map((preset) => (
        <Picture
          key={`${preset.name}-${image[preset.name].url}`}
          className={preset.className}
          url={image[preset.name].url}
          media={preset.query}
          fallbackSrc={image[preset.name].url}
          alt={image[preset.name].alt}
          title={image[preset.name].alt}
          width={preset.width}
          height={preset.height}
          attrWidth={preset.width}
          attrHeight={preset.height}
          loading={loading === 'lazy' ? loading : null}
        />
      ))}
    </>
  );
};

const propTypes = {
  small: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  medium: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  large: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
};

ResponsiveImage.propTypes = {
  image: PropTypes.shape(propTypes).isRequired,
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      className: PropTypes.string.isRequired,
      query: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
  ),
  loading: PropTypes.oneOf(['preload', 'lazy']),
};

const presets = DEFAULT_PRESETS;

export { presets, propTypes };

export default ResponsiveImage;
