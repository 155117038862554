import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';
import ChartDonut from '../../../01_atoms/ChartDonut';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

import s from './index.module.scss';

const BBSectionWithCharts = ({
  title = '',
  button = null,
  chartData,
  behaviorSettings = null,
  uuid = null,
}) => (
  <div
    className={`bb ${s['bb-section-with-charts']} ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    id={uuid}
  >
    <div className="container">
      {title && <h2 className={s['bb-section-with-charts__title']}>{title}</h2>}
      <div className={s['bb-section-with-charts__charts']}>
        {chartData.map((data) => (
          <div key={data.title} className={s['bb-section-with-charts__item']}>
            <ChartDonut {...data} />
          </div>
        ))}
      </div>
      {button && button.label && button.nextLink && (
        <div className={s['bb-section-with-charts__button-wrapper']}>
          <Link {...button.nextLink}>
            <Button
              isMobileBlock
              type="secondary"
              isOutlined
              tag="a"
              href={button.nextLink.url}
              className={s['bb-section-with-charts__button']}
            >
              {button.label}
            </Button>
          </Link>
        </div>
      )}
    </div>
  </div>
);

BBSectionWithCharts.propTypes = {
  title: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    nextLink: PropTypes.shape(linkPropTypes).isRequired,
  }),
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBSectionWithCharts;
