import PropTypes from 'prop-types';
import TruckThinIcon from '../../public/static/icons/truck-thin.svg';
import { DEFAULT_COUNTRY_CODE } from '../../utils/constants';

import s from './index.module.scss';

const ShippingInfoMessage = ({
  title = 'Shipping to Ireland and UK only',
  description = 'Physical cards are only available for addresses within Ireland and the UK. You can still select the e-card or print at home versions for any other location.',
}) => {
  if (DEFAULT_COUNTRY_CODE !== 'IE') {
    return null;
  }
  return (
    <div className={s['shipping-info-message']}>
      <TruckThinIcon className={s['shipping-info-message__icon']} />
      <div className={s['shipping-info-message__message']}>
        <div className={s['shipping-info-message__title']}>{title}</div>
        <div className={s['shipping-info-message__description']}>{description}</div>
      </div>
    </div>
  );
};

ShippingInfoMessage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ShippingInfoMessage;
