import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const BrandedBadge = ({ label, className = '' }) => {
  if (!label) {
    return null;
  }

  if (label.toLowerCase() === 'as seen on tv') {
    return <div className={`${styles.asSeenOnTV} ${className}`} />;
  }

  return <div className={`${styles.badge} ${className}`}>{label}</div>;
};

BrandedBadge.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default BrandedBadge;
