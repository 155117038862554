import React from 'react';

import { PTHubSearchResult } from './prop-types';

import HubResultCard from '../HubResultCard';
import {
  getResourceType,
  getResultCardLink,
  getResultCardImage,
} from '../../../../utils/transforms.elastic_search.js';

const HubSearchResult = ({ item, featured }) => {
  const type = item.type[0];
  const resourceType = getResourceType(type, item);
  const link = getResultCardLink(type, item);
  const image = getResultCardImage(type, item);
  let description = '';
  if ('description' in item) {
    description = item.description[0];
  }

  return (
    <HubResultCard
      type={type}
      resourceType={resourceType}
      title={item.title[0]}
      timestamp={item.created[0]}
      description={description}
      image={image}
      link={link}
      featured={featured}
    />
  );
};

HubSearchResult.propTypes = PTHubSearchResult;

export default HubSearchResult;
