import React from 'react';
import PropTypes from 'prop-types';
import HeroBackground from '../HeroBackground';
import { propTypes as defaultPicturePropTypes } from '../../../../01_atoms/DefaultPicture';
import Tagline from '../../../../01_atoms/Tagline';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBHeroTagline = ({
  imagePicture,
  videoURL = null,
  title,
  titleLine2 = null,
  behaviorSettings = null,
  uuid = null,
}) => (
  <HeroBackground
    uuid={uuid}
    className={`bb-hero-tagline ${styles['bb-hero-tagline']} ${
      titleLine2 ? styles['bb-hero-tagline--two-lines'] : ''
    } ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    imagePicture={imagePicture}
    videoURL={videoURL}
  >
    <div className="container-wrapper">
      <div className="container">
        <div className="title-wrapper">
          {/* We use empty title to overwrite title for the block. */}
          <h1 title="">
            <Tagline
              className="title title--line1"
              label={title}
              background="green"
              color="white"
              size="big"
              inverted
            />
            {titleLine2 && (
              <Tagline
                className="title title--line2"
                label={titleLine2}
                background="green"
                color="white"
                size="big"
                inverted
              />
            )}
          </h1>
        </div>
      </div>
    </div>
  </HeroBackground>
);

BBHeroTagline.propTypes = {
  imagePicture: PropTypes.shape(defaultPicturePropTypes).isRequired,
  videoURL: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleLine2: PropTypes.string,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBHeroTagline;
