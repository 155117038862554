import React from 'react';

// NOTE: this HOC is legacy and should NOT be used in new components to
// avoid performance degradation.

const ieDefaults = {
  isIE: false,
  isEdge: false,
  version: null,
};

export const IEContext = React.createContext(ieDefaults);

export default function withIE(PageComponent) {
  return class PageWithIEContext extends React.PureComponent {
    constructor(props) {
      super(props);

      this.state = ieDefaults;
    }

    static async getInitialProps(props) {
      let pageProps = {};

      if (PageComponent.getInitialProps) {
        pageProps = await PageComponent.getInitialProps(props);
      }

      return pageProps;
    }

    componentDidMount() {
      // Browser identification https://stackoverflow.com/a/9851769
      // TODO - add support for version check
      /* eslint-disable-next-line spaced-comment */
      const isIE = /*@cc_on!@*/ false || !!document.documentMode;
      const isEdge = !isIE && !!window.StyleMedia;

      /* eslint-disable-next-line react/no-unused-state */
      this.setState({ isIE, isEdge });
    }

    render() {
      return (
        <IEContext.Provider value={this.state}>
          <PageComponent {...this.props} />
        </IEContext.Provider>
      );
    }
  };
}
