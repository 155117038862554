import React from 'react';
import PropTypes from 'prop-types';
import TrackVisibility from 'react-on-screen';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import s from './index.module.scss';

const ChartDonut = ({ percentage, title = '', colorClass = 'turquoise' }) => (
  <div className={s['chart-donut']}>
    <TrackVisibility once>
      {({ isVisible }) => (
        <CircularProgressbar
          strokeWidth={10}
          className={s[`chart-donut__progress--${colorClass}`]}
          value={isVisible ? parseFloat(percentage) : 0}
          text={`${percentage}%`}
        />
      )}
    </TrackVisibility>

    {title && (
      <div className={s['chart-donut__title']}>
        <mark className={s['chart-donut__title-mobile']}>
          <strong>{title}</strong>
        </mark>
        <div className={s['chart-donut__title-desktop']}>{title}</div>
      </div>
    )}
  </div>
);

ChartDonut.propTypes = {
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string,
  colorClass: PropTypes.string,
};

export default ChartDonut;
