import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import SortDescIcon from '../../../../../public/static/icons/sort-desc.svg';
import SortAscIcon from '../../../../../public/static/icons/sort-asc.svg';

export const sortOptions = [
  {
    label: 'Sort by latest',
    dataField: 'created',
    sortBy: 'desc',
    className: `${styles['sort-by-latest']} sort-by-latest-testsuite`,
  },
  {
    label: 'Sort by oldest',
    dataField: 'created',
    sortBy: 'asc',
    className: `${styles['sort-by-oldest']} sort-by-oldest-testsuite`,
  },
];

const SortComponent = ({ onSortChange, currentSort, isHubHomePage }) => {
  return (
    <div
      className={`${styles['sort-wrapper']}${
        isHubHomePage ? ` ${styles['hub-homepage-sort']}` : ''
      } hide-when-no-results`}
    >
      <div
        tabIndex="0"
        className={`${styles['sort-item']} ${sortOptions[0].className}${
          currentSort.sortBy === sortOptions[0].sortBy
            ? ` ${styles['active']} sort-active-testsuite`
            : ''
        }`}
        onClick={() =>
          currentSort.sortBy !== sortOptions[0].sortBy ? onSortChange(sortOptions[0]) : null
        }
        onKeyPress={() =>
          currentSort.sortBy !== sortOptions[0].sortBy ? onSortChange(sortOptions[0]) : null
        }
      >
        <SortAscIcon /> {sortOptions[0].label}
      </div>
      <div
        tabIndex="0"
        className={`${styles['sort-item']} ${sortOptions[1].className}${
          currentSort.sortBy === sortOptions[1].sortBy
            ? ` ${styles['active']} sort-active-testsuite`
            : ''
        }`}
        onClick={() =>
          currentSort.sortBy !== sortOptions[1].sortBy ? onSortChange(sortOptions[1]) : null
        }
        onKeyPress={() =>
          currentSort.sortBy !== sortOptions[1].sortBy ? onSortChange(sortOptions[1]) : null
        }
      >
        <SortDescIcon /> {sortOptions[1].label}
      </div>
    </div>
  );
};

SortComponent.propTypes = {
  currentSort: PropTypes.shape({
    label: PropTypes.string,
    dataField: PropTypes.string,
    sortBy: PropTypes.string,
    className: PropTypes.string,
  }).isRequired,
  onSortChange: PropTypes.func.isRequired,
  isHubHomePage: PropTypes.bool.isRequired,
};

export default SortComponent;
