import React from 'react';
import PropTypes from 'prop-types';

import Spinner from '../../../01_atoms/Spinner';
import * as breakpoints from '../../../../utils/breakpoint';

import styles from './index.module.scss';

class PaginatorMini extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      // Defines value of current page number on the mobile.
      // Relevant only for mobile devices, because this value is displayed
      // only on mobile according to the designs.
      mobilePageCurrent: 1,
    };

    this.onPrevClick = this.onPrevClick.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
  }

  /**
   * Handles click on prev page icon.
   */
  async onPrevClick() {
    const { onPrevPageClick, mobileItemsScroll, desktopItemsScroll } = this.props;
    const { isLoading, mobilePageCurrent } = this.state;

    // If there are no previous items or the pager is still in the loading
    // state then we should not handle another click on the item.
    if (mobilePageCurrent === 1 || isLoading) {
      return;
    }

    this.setState({ isLoading: true });
    try {
      await onPrevPageClick();
    } catch (_e) {
      // TODO
    }

    this.setState((state) => {
      let prevMobilePage = state.mobilePageCurrent;

      // If it's tablet or desktop we should decrease the current pager by 4,
      // because on tablet / desktop it's assumed to have 4 items change per
      // pager update, while it's still 1 on mobile.
      prevMobilePage -= breakpoints.isUp('md') ? desktopItemsScroll : mobileItemsScroll;

      // Make sure that the current page indicator never goes below first page.
      prevMobilePage = prevMobilePage > 1 ? prevMobilePage : 1;

      return {
        isLoading: false,
        mobilePageCurrent: prevMobilePage,
      };
    });
  }

  /**
   * Handles click on next page icon.
   */
  async onNextClick() {
    const { onNextPageClick, itemsTotal, mobileItemsScroll, desktopItemsScroll } = this.props;
    const { isLoading, mobilePageCurrent } = this.state;

    // If there are no items to show next or the pager is still in the loading
    // state then we should not handle another click on the item.
    if (mobilePageCurrent === itemsTotal || isLoading) {
      return;
    }

    this.setState({ isLoading: true });
    try {
      await onNextPageClick();
    } catch (_e) {
      // TODO
    }

    this.setState((state) => {
      let nextMobilePage = state.mobilePageCurrent;

      // If it's tablet or desktop we should increase the current pager by 4,
      // because on tablet / desktop it's assumed to have 4 items change per
      // pager update, while it's still 1 on mobile.
      nextMobilePage += breakpoints.isUp('md') ? desktopItemsScroll : mobileItemsScroll;

      // Make sure that the current page indicator never goes beyond total
      // amount of items.
      nextMobilePage = itemsTotal && itemsTotal > nextMobilePage ? nextMobilePage : itemsTotal;

      return {
        isLoading: false,
        mobilePageCurrent: nextMobilePage,
      };
    });
  }

  render() {
    const { itemsTotal, currentPage, pagesTotal } = this.props;
    const { isLoading, mobilePageCurrent } = this.state;

    const isPrevActive =
      Number.isInteger(currentPage) && Number.isInteger(pagesTotal)
        ? currentPage > 0
        : mobilePageCurrent > 1;
    const isNextActive =
      Number.isInteger(currentPage) && Number.isInteger(pagesTotal)
        ? currentPage < pagesTotal - 1
        : mobilePageCurrent < itemsTotal;

    return (
      <div className={`paginator-mini ${styles['paginator-mini']}`}>
        <div
          className={`arrow prev ${isPrevActive ? 'active' : 'disabled'} ${
            isLoading ? 'loading' : ''
          }`}
          onClick={isPrevActive ? this.onPrevClick : null}
          onKeyPress={isPrevActive ? this.onPrevClick : null}
        />

        {isLoading && <Spinner />}

        {!isLoading && itemsTotal > 0 && (
          <p className="counter">
            <strong>
              {mobilePageCurrent} / {itemsTotal}
            </strong>
          </p>
        )}

        <div
          className={`arrow next ${isNextActive ? 'active' : 'disabled'} ${
            isLoading ? 'loading' : ''
          }`}
          onClick={isNextActive ? this.onNextClick : null}
          onKeyPress={isNextActive ? this.onNextClick : null}
        />
      </div>
    );
  }
}

const PTPaginatorMini = {
  onPrevPageClick: PropTypes.func.isRequired,
  onNextPageClick: PropTypes.func.isRequired,
  itemsTotal: PropTypes.number.isRequired,
  // Needed to control state from external sources, such as backend authority
  currentPage: PropTypes.number,
  // Needed to control state from external sources, such as backend authority
  pagesTotal: PropTypes.number,
  mobileItemsScroll: PropTypes.number, // Amount of items to change on mobile pager update.
  desktopItemsScroll: PropTypes.number, // Amount of items to change on desktop pager update.
};

PaginatorMini.propTypes = PTPaginatorMini;
PaginatorMini.defaultProps = {
  mobileItemsScroll: 1,
  desktopItemsScroll: 4,
  currentPage: null,
  pagesTotal: null,
};

export { PTPaginatorMini };

export default PaginatorMini;
