import React from 'react';
import PropTypes from 'prop-types';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBHeroHeading = ({ title, description = '', behaviorSettings = null, uuid = null }) => (
  <div
    className={`bb container ${styles['bb-hero-heading']} ${generateClassNameByBehaviorSettings(
      behaviorSettings,
    )}`}
    id={uuid}
  >
    {title && <h1 className="title">{title}</h1>}
    {description && <div className="description">{description}</div>}
  </div>
);

BBHeroHeading.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBHeroHeading;
