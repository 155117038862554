import React from 'react';
import PropTypes from 'prop-types';
import { LinkTitle } from '../../../../02_molecules/Link';
import Link, { propTypes as linkPropTypes } from '../../../../01_atoms/Link';
import Button from '../../../../01_atoms/Button';
import TagText from '../../../../01_atoms/Tag/TagText';
import { propTypes as imagePropTypes } from '../index';
import FormattedText from '../../../../01_atoms/FormattedText';
import YoutubeVideoInPopup from '../../../../02_molecules/YoutubeVideoInPopup';
import ResponsiveImage from '../../../../01_atoms/ResponsiveImage';
import LoopingVideo from '../../../../01_atoms/LoopingVideo';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

import baseStyles from '../index.module.scss';
import styles from './index.module.scss';

class BBHeroVideo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playVideo: false,
    };

    // A DOM reference to control playback of the Hero video when the Youtube video is played.
    this.heroVideoRef = React.createRef();

    this.onKeyPress = this.onKeyPress.bind(this);
    this.onVideoPopupOpen = this.onVideoPopupOpen.bind(this);
    this.onVideoPopupClose = this.onVideoPopupClose.bind(this);
  }

  onKeyPress(event) {
    const { playVideo } = this.state;
    if (event.keyCode === 27 && playVideo === true) {
      this.onVideoPopupClose();
    }
  }

  onVideoPopupOpen(event) {
    document.addEventListener('keydown', this.onKeyPress, false);
    event.preventDefault();
    if (this.heroVideoRef.current) {
      this.heroVideoRef.current.pause();
    }
    this.setState({ playVideo: true });
  }

  onVideoPopupClose() {
    if (this.heroVideoRef.current) {
      this.heroVideoRef.current.play();
    }
    this.setState({ playVideo: false });
    document.removeEventListener('keydown', this.onKeyPress, false);
  }

  render() {
    const {
      title,
      description,
      image,
      videoText,
      videoPreviewURL,
      videoProps,
      fallbackCTA,
      isEmergency,
      tag,
      tagLabel,
      behaviorSettings,
      uuid,
    } = this.props;
    const { playVideo } = this.state;

    const renderFullVideo = videoText && videoProps.videoURL;
    const renderFallbackCTA =
      !renderFullVideo && fallbackCTA && fallbackCTA.label && fallbackCTA.nextLink;

    const imageProps = {
      image,
      loading: 'preload',
    };

    // Remove desktop preset and slightly change tablet.
    if (videoPreviewURL) {
      imageProps.presets = [
        {
          name: 'small',
          className: 'responsive-image responsive-image-mobile',
          query: '(max-width: 420px)',
          width: 420,
          height: 280,
        },
        {
          name: 'medium',
          className: 'responsive-image responsive-image-tablet d-md-none',
          query: '(min-width: 421px) and (max-width: 768px)',
          width: 992,
          height: 558,
        },
      ];
    }

    const classes = [
      'bb',
      'bb-hero',
      'bb-hero-video',
      baseStyles['bb-hero'],
      styles['bb-hero-video'],
      videoPreviewURL ? styles['with-preview-video'] : '',
      isEmergency ? 'bb-hero-video-emergency' : '',
      generateClassNameByBehaviorSettings(behaviorSettings),
    ];

    return (
      <div className={classes.join(' ')} id={uuid}>
        <ResponsiveImage {...imageProps} />

        {videoPreviewURL && (
          <div className="video-container d-none d-md-block">
            <LoopingVideo reference={this.heroVideoRef} src={videoPreviewURL} />
          </div>
        )}

        <div className="gradient" />

        <div className="container-wrapper">
          <div className="container">
            <div className="row">
              <div className="overlay col-12 col-lg-10 col-xl-8">
                {isEmergency && tag && (
                  <>
                    <TagText label={tagLabel} backgroundColor="red" isUppercase />

                    <TagText label={tag} backgroundColor="black-emergency" isUppercase />
                  </>
                )}

                <h1 className="header-two rockitt">{title}</h1>

                <div className="d-none d-md-block">
                  {description && <FormattedText className="description" text={description} />}

                  {renderFullVideo && (
                    <>
                      <LinkTitle label={videoText} url="#" onClick={this.onVideoPopupOpen} />

                      <YoutubeVideoInPopup
                        isVisible={playVideo}
                        onPopupClose={this.onVideoPopupClose}
                        videoProps={videoProps}
                        acceptCookiesButtonClassName="allow-cookies-video-hero-bb"
                      />
                    </>
                  )}
                </div>

                {renderFallbackCTA && !isEmergency && (
                  <LinkTitle label={fallbackCTA.label} url={fallbackCTA.nextLink.url} />
                )}
                {renderFallbackCTA && isEmergency && (
                  <Link {...fallbackCTA.nextLink}>
                    <Button
                      tag="a"
                      type="emergency"
                      isMobileBlock
                      href={fallbackCTA.nextLink.url}
                      className="bb-hero-emergency--button bb-hero--button"
                    >
                      {fallbackCTA.label}
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BBHeroVideo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape(imagePropTypes).isRequired,
  videoText: PropTypes.string,
  videoPreviewURL: PropTypes.string,
  showScrollButton: PropTypes.bool,
  fallbackCTA: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape(linkPropTypes),
  }),
  videoProps: PropTypes.shape({
    videoURL: PropTypes.string,
    cookieConsentType: PropTypes.string,
  }),
  isEmergency: PropTypes.bool,
  tag: PropTypes.string,
  tagLabel: PropTypes.string,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

BBHeroVideo.defaultProps = {
  videoText: null,
  videoProps: {
    videoURL: null,
    cookieConsentType: null,
  },
  videoPreviewURL: null,
  showScrollButton: false,
  fallbackCTA: null,
  isEmergency: false,
  tag: null,
  tagLabel: 'Emergency',
  behaviorSettings: null,
  uuid: null,
};

export default BBHeroVideo;
