import React from 'react';
import PropTypes from 'prop-types';
import FormattedText from '../../../../01_atoms/FormattedText';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBText = ({ text, size = 'normal', behaviorSettings = null, uuid = null }) => (
  <div
    className={`bb ${styles['bb-text']} ${styles[`bb-text-${size}`]} ${generateClassNameByBehaviorSettings(
      behaviorSettings,
    )}`}
    data-component={'bb-text'}
    id={uuid}
  >
    {/* eslint-disable-next-line react/no-danger */}
    <FormattedText className="container" text={text} />
  </div>
);

BBText.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['large', 'normal']),
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBText;
