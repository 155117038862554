import React from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import styles from './index.module.scss';

const ReactiveSearchAware = ({ children }) => {
  const hubSearchIsTouchedOnClient = useSelector((state) => state.search.hubSearchIsTouched);
  const router = useRouter();
  // Ideally we want to be able to update Redux store from BBHubSearch on the
  // server but for now router query check works better.
  let hubSearchIsTouchedOnServer = false;
  if (router.query && !hubSearchIsTouchedOnClient) {
    const searchQueryExists = Object.keys(router.query).findIndex(
      (param) => param === 'search' || param === 'result' || param.startsWith('filter'),
    );

    if (searchQueryExists !== -1) {
      // When search query param appears first time, it means search is touched.
      hubSearchIsTouchedOnServer = true;
    }
  }

  const isTouched = hubSearchIsTouchedOnClient || hubSearchIsTouchedOnServer;
  return (
    <div
      className={`reactive-search-aware ${
        isTouched ? styles['search-touched'] : styles['search-not-touched']
      }`}
    >
      {children}
    </div>
  );
};

export default ReactiveSearchAware;
