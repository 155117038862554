import React from 'react';
import { connect as reactiveSearchConnect } from '@appbaseio/reactivesearch/lib/utils';
import { clearValues } from '@appbaseio/reactivecore/lib/actions';
import NoResultsBlock from '../../../../01_atoms/SearchElements/NoResults/NoResultsBlock';
import NoResultsLinks from '../../../../01_atoms/SearchElements/NoResults/NoResultsLinks';
import { PTHubSearchNoResults } from './prop-types';

import styles from './index.module.scss';

const HubSearchNoResults = ({
  searchValue,
  countSelectedFiltersForMessage,
  noResultsText,
  exploreLinks = [],
  clearValues,
}) => {
  return (
    <div className={`hub-search-no-results ${styles['hub-search-no-results']}`}>
      <div className="hub-search-no-results-container">
        <div className="hub-search-no-results-result">
          No results for {searchValue}{' '}
          {countSelectedFiltersForMessage > 0
            ? ` + ${countSelectedFiltersForMessage} ${
                countSelectedFiltersForMessage === 1 ? 'filter' : 'filters'
              }`
            : ''}
        </div>
        <NoResultsBlock
          onButtonClick={() => clearValues()}
          text={noResultsText}
          analyticsClassName="analytics-hub-search-no-results-clear"
        />
        {exploreLinks && !!exploreLinks.length && (
          <NoResultsLinks
            links={exploreLinks}
            analyticsClassName="analytics-hub-search-no-results-recommended"
            title="Explore Knowledge Hub:"
          />
        )}
      </div>
    </div>
  );
};

HubSearchNoResults.propTypes = PTHubSearchNoResults;

const mapReactiveSearchDispatchToProps = (dispatch) => ({
  clearValues: () => dispatch(clearValues()),
});

export default reactiveSearchConnect(null, mapReactiveSearchDispatchToProps)(HubSearchNoResults);
