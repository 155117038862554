import React from 'react';

import styles from './index.module.scss';

const ActivismPage = ({ children, ...props }) => (
  <div className={styles.activism} {...props}>
    {children}
  </div>
);

export default ActivismPage;
