import React from 'react';
import { FieldInputProps } from 'react-final-form';
import { RadioButtonOptions } from '../../../01_atoms/FormElements/RadiosButton';
import { Field, RadiosButton } from '../../../01_atoms/FormElements';
import ImpactText from '../../../01_atoms/ImpactText';

export type MoneyHandle = {
  amount: number;
  value?: string;
  label?: string;
};

type Props = {
  moneyHandles: {
    single_donation: MoneyHandle[];
    recurring_donation: MoneyHandle[];
  };
  donationType: 'single_donation' | 'recurring_donation';
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  mutateFormValue: (...args: any[]) => void;
  currentImpact?: {
    amount: number;
    text: string;
    image?: {
      url: string;
      alt: string;
    };
  };
  styling?: 'default' | 'emergency' | 'emergency-black' | 'dec';
};

const MoneyHandles = ({
  moneyHandles,
  donationType,
  currentImpact,
  mutateFormValue,
  styling = 'default',
}: Props) => {
  function onMoneyHandleChange(
    event: React.ChangeEvent<HTMLInputElement>,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    input: FieldInputProps<any, HTMLElement>,
  ) {
    // Call default onChange handler.
    input.onChange(event);

    const moneyHandleIndex = Number(event.target.value);
    const moneyHandleAmount = moneyHandles[donationType][moneyHandleIndex].amount;
    // Updates custom amount field on money handle change.
    mutateFormValue(`amount.${donationType}`, `${moneyHandleAmount}`);
  }

  const options = currentImpact
    ? moneyHandles[donationType].map((item) =>
        item.amount === currentImpact.amount ? { ...item, className: 'show-impact-arrow' } : item,
      )
    : moneyHandles[donationType];

  function onKeyDownPress(e: React.KeyboardEvent<Element>, item: RadioButtonOptions) {
    const enter = 13;
    if (e.keyCode === enter && mutateFormValue) {
      // Updates checked state
      mutateFormValue(`money_handle.${donationType}`, `${item.value}`);
      // Updates custom amount field on money handle change.
      mutateFormValue(`amount.${donationType}`, `${item.amount}`);
    }
  }

  return (
    <>
      <Field name={`money_handle.${donationType}`} type="RadiosButton">
        {({ input }) => (
          <RadiosButton
            name={`money_handle.${donationType}`}
            size="large"
            label="How much would you like to give?"
            // onChange prop is not defined in react-final-form type.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onMoneyHandleChange(ev, input)}
            onKeyDown={onKeyDownPress}
            mutateFormValue={mutateFormValue}
            options={options}
            styling={styling === 'dec' ? 'default' : styling}
          />
        )}
      </Field>
      {currentImpact && (
        <ImpactText
          text={currentImpact.text}
          image={currentImpact.image}
          className="impact-message-container"
          styling={['emergency', 'emergency-black'].includes(styling) ? 'emergency' : 'default'}
        />
      )}
    </>
  );
};

export default MoneyHandles;
