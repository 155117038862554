import React from 'react';
import PropTypes from 'prop-types';
import TileItem, { propTypes as tileItemPropTypes } from '../../../02_molecules/TileItem';
import TileItemActivism, {
  propTypes as tileItemActivismPropTypes,
} from '../../../02_molecules/TileItemActivism';
import {
  generateClassNameByBehaviorSettings,
  behaviorSettingsProps,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBThreeTile = ({
  title = '',
  items,
  className = '',
  variant = 'default',
  behaviorSettings = null,
  uuid = null,
}) => (
  <div
    className={`bb bb-three-tile ${
      styles['bb-three-tile']
    } variant-${variant} ${className} ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    id={uuid}
  >
    <div className="container">
      {title && <h2 className="bb-three-tile-heading">{title}</h2>}
      <ul className="bb-three-tile-items">
        {items.map((item, index) =>
          variant === 'activism' ? (
            <TileItemActivism
              {...item}
              key={`${item.primaryCTA.nextLink.url}_${item.title}`}
              position={index + 1}
            />
          ) : (
            <TileItem
              {...item}
              key={`${item.primaryCTA.nextLink.url}_${item.title}`}
              position={index + 1}
            />
          ),
        )}
      </ul>
    </div>
  </div>
);

BBThreeTile.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(tileItemPropTypes),
      PropTypes.shape(tileItemActivismPropTypes),
    ]),
  ).isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'activism']),
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBThreeTile;
