import { string, shape, arrayOf, number } from 'prop-types';
import { propTypes as linkPropTypes } from '../../../../01_atoms/Link';

export const PTHubSearchNoResults = {
  searchValue: string,
  countSelectedFiltersForMessage: number,
  noResultsText: string,
  exploreLinks: arrayOf(
    shape({
      nextLink: shape(linkPropTypes),
      label: string,
    }),
  ),
};
