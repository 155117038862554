import React from 'react';
import withIE, { IEContext } from '../../05_hoc/withIE';

type Props = {
  title?: string;
  alt?: string;
  width?: number;
  height?: number;
  attrWidth?: number;
  attrHeight?: number;
  className?: string;
  loading?: 'eager' | 'lazy';
};

type WithFallbackProps = {
  title?: string;
  alt?: string;
  width?: number;
  height?: number;
  loading?: 'eager' | 'lazy';
  fallbackSrc?: string;
};

const EmptyImage = ({
  title = '',
  alt = '',
  width = 1920,
  height = 1080,
  className = '',
  attrWidth,
  attrHeight,
  loading,
}: Props) => (
  <img
    src={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${width} ${height}'%3E%3C/svg%3E`}
    width={attrWidth}
    height={attrHeight}
    alt={alt}
    title={title}
    className={className}
    loading={loading}
  />
);

/**
 * <EmptyImage> with fallback to a given src in Internet Explorer.
 */
export const EmptyImageWithFallbackForIE = withIE(
  ({ fallbackSrc, alt, width, height, title, loading }: WithFallbackProps) => (
    <IEContext.Consumer>
      {({ isIE }) =>
        isIE ? (
          <img
            src={fallbackSrc}
            alt={alt}
            width={width}
            height={height}
            title={title}
            loading={loading}
          />
        ) : (
          <EmptyImage
            alt={alt}
            title={title}
            width={width}
            height={height}
            attrWidth={width}
            attrHeight={height}
            loading={loading}
          />
        )
      }
    </IEContext.Consumer>
  ),
);

export default EmptyImage;
