import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

// Auto-playing videos are loaded on mobile browsers even if they are hidden
// via CSS. To avoid downloading unnecessary video file on mobile we render
// proper <video> only on desktop.
const LoopingVideo = ({ src, reference = null }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (window.matchMedia && window.matchMedia('(min-width: 768px)').matches) {
      setShow(true);
    }
  }, []);

  return (
    <>
      <Head>
        <link rel="preload" href={src} as="video" media="(min-width: 768px)" />
      </Head>
      <video ref={reference} autoPlay muted loop>
        {show && <source src={src} type="video/mp4" />}
      </video>
    </>
  );
};

LoopingVideo.propTypes = {
  src: PropTypes.string.isRequired,
  reference: PropTypes.oneOfType([
    PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export default LoopingVideo;
