import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { propTypes as defaultPicturePropTypes } from '../../../../01_atoms/DefaultPicture';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';
import { propTypes as linkPropTypes } from '../../../../01_atoms/Link';
import FormattedText from '../../../../01_atoms/FormattedText';
import BlockFooter from '../../../../02_molecules/BlockFooter';
import useScreenSize from '../../../../../hooks/useScreenSize';
import { pushViewItem as gtmPushViewItem } from '../../../../../utils/gtm';
import HeroSocialAdWrapper from './HeroSocialAdWrapper';

import s from './index.module.scss';

const BBHeroSocialAd = ({
  logoLink,
  title,
  image,
  heading,
  buttonDescription = '',
  button,
  label = '',
  sectionContent,
  behaviorSettings = null,
  uuid = null,
  appealId = null,
  appealTitle = '',
  appealDefault = 'single',
  variant = 'default',
}) => {
  const { width } = useScreenSize();

  useEffect(() => {
    if (appealId) {
      gtmPushViewItem([
        {
          item_id: appealId,
          item_name: appealTitle,
          item_category: 'Appeal',
          item_variant: appealDefault,
        },
      ]);
    }
  }, [uuid, appealId, appealTitle, appealDefault]);

  const isDesktop = width >= 1200;

  const classes = [
    'bb',
    'bb-hero',
    s['bb-hero-social-ad'],
    s[`bb-hero-social-ad--variant-${variant}`],
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];

  return (
    <HeroSocialAdWrapper
      heading={title}
      uuid={uuid}
      classnames={classes.join(' ')}
      logoLink={logoLink}
      variant={variant}
      image={image}
      contentLeft={
        <>
          <FormattedText tag="h2" className={s['bb-hero-social-ad__heading']} text={heading} />
          {isDesktop && button?.nextLink && (
            <BlockFooter button={button} variant={variant} buttonDescription={buttonDescription} />
          )}
        </>
      }
      contentRight={
        <>
          {label && <div className={s['bb-hero-social-ad__label']}>{label}</div>}
          {sectionContent.type === 'text' && (
            <FormattedText
              className={s['bb-hero-social-ad__highlighted-text']}
              text={sectionContent.text}
            />
          )}
          {sectionContent.type === 'list' && (
            <ul
              className={`${s['bb-hero-social-ad__list']} ${
                s[`bb-hero-social-ad__list--${sectionContent.variant}`]
              }`}
            >
              {sectionContent.items.map(
                (item) =>
                  item.title &&
                  item.text && (
                    <li className={s['bb-hero-social-ad__list-item']} key={item.title}>
                      {item.title && (
                        <h3 className={s['bb-hero-social-ad__list-item-title']}>{item.title}</h3>
                      )}
                      {item.text && (
                        <FormattedText
                          className={s['bb-hero-social-ad__list-item-text']}
                          text={item.text}
                        />
                      )}
                    </li>
                  ),
              )}
            </ul>
          )}
        </>
      }
      contentBottom={
        <>
          {!isDesktop && button?.nextLink && (
            <BlockFooter variant={variant} button={button} buttonDescription={buttonDescription} />
          )}
        </>
      }
    />
  );
};

BBHeroSocialAd.propTypes = {
  logoLink: PropTypes.shape(linkPropTypes).isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape(defaultPicturePropTypes).isRequired,
  heading: PropTypes.string.isRequired,
  buttonDescription: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    nextLink: PropTypes.shape(linkPropTypes).isRequired,
  }).isRequired,
  label: PropTypes.string,
  sectionContent: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf(['text']),
      text: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      type: PropTypes.oneOf(['list']),
      variant: PropTypes.oneOf(['green', 'yellow']).isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          text: PropTypes.string,
        }),
      ),
    }),
  ]).isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
  appealId: PropTypes.number,
  appealTitle: PropTypes.string,
  appealDefault: PropTypes.oneOf(['single', 'monthly']),
  variant: PropTypes.oneOf(['default', 'emergency']),
};

export default BBHeroSocialAd;
