import React from 'react';
import PropTypes from 'prop-types';
import Link, { propTypes as linkPropTypes } from '../../../Link';
import FormattedText from '../../../../components/01_atoms/FormattedText';
import Button from '../../../Button';
import ArrowIcon from '../../../../public/static/icons/arrows/chevron-rounded.svg';
import RepeatIcon from '../../../../public/static/icons/repeat.svg';
import Popup from '../../../Popup';

import s from './index.module.scss';

const SantaLetterModal = ({ title, description, link, onClose }) => (
  <Popup
    closeButton={<></>}
    isOpen={true}
    shouldCloseOnOverlayClick={false}
    overlayClassName={`${s['santa-letter-modal']} ${s['santa-letter-modal__important']}`}
  >
    <div className={s['santa-letter-modal__content']}>
      <div className={s['santa-letter-modal__image']} />
      <FormattedText tag="h4" text={title} className={s['santa-letter-modal__title']} />
      {description && (
        <FormattedText text={description} className={s['santa-letter-modal__description']} />
      )}
      <div className={s['santa-letter-modal__buttons']}>
        <Button
          buttonType="button"
          type="dark"
          onClick={onClose}
          onKeyDown={(e) => e.key === 'Enter' && onClose()}
        >
          <RepeatIcon /> Create another letter
        </Button>
        <Link href="/basket">
          <Button tag="a" type="dark">
            Go to checkout <ArrowIcon className={s['santa-letter-modal__checkout-icon']} />
          </Button>
        </Link>
        {link?.nextLink?.href && (
          <Link {...link.nextLink}>
            <Button tag="a" isOutlined type="dark">
              {link.label}
            </Button>
          </Link>
        )}
      </div>
    </div>
  </Popup>
);

SantaLetterModal.propTypes = {
  link: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape(linkPropTypes),
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default SantaLetterModal;
