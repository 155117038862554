import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const defaultGrid = styles['bb-grid-default'];

// Some blocks override default 8-column grid (usually on large desktop only).
const overrideGridForBlock = {
  text_heading_2: styles['bb-grid-heading'],
  image_quote: styles['bb-grid-quote'],
  simple_quote: styles['bb-grid-quote'],
  media_image_grid: styles['bb-grid-9'],
  inline_image: styles['bb-grid-9'],
  inline_image_with_caption: styles['bb-grid-9'],
  map_with_countries: styles['bb-grid-9'],
  text_grid: styles['bb-grid-9'],
  numbered_text_grid: styles['bb-grid-9'],
};

const LeftColumn = ({ children }) => (
  <div className={`container left-column-layout ${styles['left-column-layout']}`}>
    {Array.isArray(children) &&
      children.map((item, itemIndex) => {
        return (
          <div
            key={`layout.${item.props.id}.${itemIndex}`}
            className={`${defaultGrid} ${
              overrideGridForBlock[item.props.blockType]
                ? overrideGridForBlock[item.props.blockType]
                : ''
            }`}
          >
            <React.Fragment key={itemIndex}>{item}</React.Fragment>
          </div>
        );
      })}

    {!Array.isArray(children) && <>{children}</>}
  </div>
);

LeftColumn.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default LeftColumn;
