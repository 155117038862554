import React from 'react';
import PropTypes from 'prop-types';

import DropdownFiltersForm, {
  PTDropdownFiltersForm,
} from '../../../02_molecules/DropdownFiltersForm';
import BBRelatedContent from '../BBRelatedContent';
import BBTeaserWithPager, { PTBBTeaserWithPager } from '../BBTeaserWithPager';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

import s from './index.module.scss';

class BBContentOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      topics: props.topics,
      filteredContents: null,
    };

    this.onPaginate = this.onPaginate.bind(this);
  }

  /**
   * Filtering will return a lits of all items for that particular combination of
   * filter keywords. It replaces the per-topic paginated display with a grid
   * of these items.
   */
  async onFilter({ options, callback }) {
    const filter = Object.keys(options);

    // If the filters have been cleared, show the original topics listings
    if (filter.length === 0) {
      this.setState((state) => ({
        ...state,
        filter: {},
        filteredContents: null,
      }));
    } else {
      // Flatten the returned selection to only key-value pairs
      const query = {};

      filter.forEach((option) => {
        query[option] = options[option].value;
      });

      const items = await callback(query);

      this.setState((state) => ({
        ...state,
        filter: query,
        filteredContents: {
          isMobileCollapsed: false,
          items: items.map((item) => ({
            ...item,
            isDesktopDescriptionHidden: false,
            isMobileDescriptionHidden: false,
          })),
        },
      }));
    }
  }

  /**
   * Paginating will return new items for a topic for the next or previous page.
   * The filter is ignored. The initial render will always return 5 items per page
   * to enable mobile view. Any pagination request, which only ever occurs on desktop,
   * will request 4 items per page via the API.
   */
  async onPaginate({ callback, index, itemsPerPage }) {
    // eslint-disable-next-line
    const currentPage = this.state.topics[index].pager.currentPage;
    const { items, page } = await callback({ currentPage, itemsPerPage });

    this.setState((state) => {
      const { topics } = state;

      topics[index].items = items;
      topics[index].pager.currentPage = page;

      return {
        ...state,
        topics,
      };
    });
  }

  render() {
    const { filter, behaviorSettings, uuid } = this.props;
    const { topics, filteredContents } = this.state;

    const classes = [
      'bb',
      s['bb-content-overview'],
      generateClassNameByBehaviorSettings(behaviorSettings),
    ];

    return (
      <div className={classes.join(' ')} id={uuid}>
        <div className="container">
          {filter && (
            <DropdownFiltersForm
              {...filter}
              onSubmit={(options) => this.onFilter({ options, callback: filter.onSubmit })}
            />
          )}

          {filteredContents ? (
            <BBRelatedContent {...filteredContents} />
          ) : (
            topics.map((topic, index) => (
              <BBTeaserWithPager
                key={`${topic.key}-${index}` /* eslint-disable-line react/no-array-index-key */}
                {...topic}
                pager={{
                  ...topic.pager,
                  onNextPageClick: () =>
                    this.onPaginate({
                      callback: topic.pager.onNextPageClick,
                      index,
                    }),
                  onPrevPageClick: () =>
                    this.onPaginate({
                      callback: topic.pager.onPrevPageClick,
                      index,
                    }),
                }}
              />
            ))
          )}
        </div>
      </div>
    );
  }
}

BBContentOverview.propTypes = {
  filter: PropTypes.shape(PTDropdownFiltersForm),
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      ...PTBBTeaserWithPager,
      key: PropTypes.string,
    }),
  ).isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

BBContentOverview.defaultProps = {
  filter: null,
  behaviorSettings: null,
  uuid: null,
};

export default BBContentOverview;
