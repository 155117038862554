import React, { AllHTMLAttributes, createElement } from 'react';
import { ChildrenProps } from '../../../types';
import Arrow from '../../../public/static/icons/arrow-right.svg';

import styles from './index.module.scss';

type Props = {
  tag?: 'button' | 'a';
  type?: 'default' | 'light';
  size?: 'small' | 'large' | 'extra-large';
  className: string;
  children?: ChildrenProps;
  isDisabled?: boolean;
  withArrow?: boolean;
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  tabIndex?: number;
};

const TextButton = ({
  type = 'default',
  size = 'large',
  className = '',
  isDisabled = false,
  children = '',
  withArrow = false,
  onClick = () => {},
  onKeyDown = () => {},
  tabIndex = 0,
  tag = 'button',
}: Props) => {
  // Temporary duplicating css module classes by global ones,
  // until importing values will be fixed on Storybook level.
  const classes = [
    className,
    styles['text-button'],
    styles[`text-button--${type}`],
    styles[`text-button--${size}`],
    'text-button',
    `text-button--${type}`,
    `text-button--${size}`,
  ];

  if (withArrow) {
    classes.push(styles['text-button--with-arrow']);
    classes.push('text-button--with-arrow');
  }

  const generatedClassName = classes.join(' ');

  // For better accessibility disabled buttons remain focusable. For that reason we have to
  // prevent default behaviour for aria-disabled buttons.
  const handleClick = (e: React.MouseEvent) => {
    if (isDisabled) {
      e.preventDefault();
    } else {
      onClick(e);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (onKeyDown) {
      onKeyDown(e);
      return;
    }

    const enter = 13;
    if (e.keyCode === enter) {
      onClick(e);
    }
  };

  const btnProps: AllHTMLAttributes<HTMLAnchorElement | HTMLButtonElement> = {};
  if (tag === 'button') {
    btnProps.type = 'submit';
  }

  return createElement(
    tag,
    {
      ...btnProps,
      tabIndex,
      className: generatedClassName,
      'aria-disabled': tag !== 'a' ? isDisabled : null,
      onClick: handleClick,
      onKeyDown: handleKeyDown,
      'data-component': 'text-button',
    },
    <>
      <span>{children}</span>
      {withArrow && <Arrow aria-hidden="true" />}
    </>,
  );
};

export default TextButton;
