import PropTypes from 'prop-types';
import CNETButton from '../../components/01_atoms/Button';

import styles from './index.module.scss';

const Button = (props) => {
  return <CNETButton {...props} className={`${styles['gifts-button']} ${props.className || ''}`} />;
};

Button.propTypes = {
  /**
   * Uses `<a>` or `<button>` tags in html.
   */
  tag: PropTypes.oneOf(['button', 'a']),
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'emergency',
    'white-pink',
    'white-green',
    'quick-link',
    'toggled',
    'untoggled',
    'activism',
    'activism-yellow',
    'activism-black',
    'tangerine',
    'cobalt',
    'xmas',
    'dark',
    'neutral',
    'light',
  ]),
  size: PropTypes.oneOf(['small', 'large', 'extra-large']),
  /**
   * Adds class to a button.
   */
  className: PropTypes.string,
  /**
   * Usually it is a label for a button.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isDisabled: PropTypes.bool,
  isOutlined: PropTypes.bool,
  /**
   * Displays loading icon on a button.
   */
  isLoading: PropTypes.bool,
  /**
   * Displays as a block.
   */
  isBlock: PropTypes.bool,
  /**
   * Displays uppercase label for a button.
   */
  isUppercase: PropTypes.bool,
  /**
   * Displays as a block on mobile devices and as inline element on other devices.
   */
  isMobileBlock: PropTypes.bool,
  /**
   * Adds external icon on the button.
   */
  isExternal: PropTypes.bool,
  /**
   * Adds arrow on the button.
   */
  withArrow: PropTypes.bool,
  withBackArrow: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
};

export default Button;
