import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../01_atoms/Link';
import Button from '../../01_atoms/Button';
import EmptyImage from '../../01_atoms/EmptyImage';
import BrandedBadge from '../../01_atoms/BrandedBadge';

import styles from './index.module.scss';

const TileItem = ({ title, image, primaryCTA, label = '', viewMode, className = '', position }) => (
  <li className={`tile-item ${styles['tile-item']} ${className}`}>
    <Link {...primaryCTA.nextLink}>
      <a
        href={primaryCTA.nextLink.url}
        className="tile-item-container"
        data-title={primaryCTA.nextLink.title ? primaryCTA.nextLink.title : title}
        data-category="Three tile block"
        data-position={position}
        data-variant={viewMode.startsWith('green') ? 'Green' : 'Pink'}
      >
        <div
          style={{
            backgroundImage: `url("${image.url}")`,
            backgroundColor: viewMode.startsWith('green') ? '#007c76' : '#d41b66',
          }}
          className="tile-item-background"
        >
          <EmptyImage className="d-lg-none" width={450} height={286} aria-hidden="true" />
          <EmptyImage className="d-none d-lg-block" width={450} height={450} aria-hidden="true" />
        </div>

        <div className="tile-item-box">
          {label && <BrandedBadge label="as seen on TV" className="tile-badge" />}
          <div className="tile-item-title">{title}</div>
          <Button
            tag="button"
            type={viewMode.startsWith('green') ? 'secondary' : 'primary'}
            size="large"
            className="tile-item-button"
            withArrow
            tabIndex="-1"
          >
            {primaryCTA.label}
          </Button>
        </div>
      </a>
    </Link>
  </li>
);

const propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  primaryCTA: PropTypes.shape({
    label: PropTypes.string.isRequired,
    nextLink: PropTypes.shape(linkPropTypes).isRequired,
  }).isRequired,
  viewMode: PropTypes.oneOf(['green-1', 'green-2', 'pink-1']).isRequired,
  label: PropTypes.oneOf(['', 'asSeenOnTV']),
  className: PropTypes.string,
};

TileItem.propTypes = {
  ...propTypes,
  position: PropTypes.number.isRequired,
};

export { propTypes };

export default TileItem;
