import React from 'react';
import PropTypes from 'prop-types';
import {
  generateClassNameByBehaviorSettings,
  behaviorSettingsProps,
} from '../../../../utils/behaviorSettings';
import DefaultPicture, { propTypes as picturePropTypes } from '../../../01_atoms/DefaultPicture';
import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';
import BrandedBadge from '../../../01_atoms/BrandedBadge';

import styles from './index.module.scss';

const BBTwoThreeTiles = ({ heading = '', items, behaviorSettings = null, uuid = null }) => (
  <div
    className={`bb bb-two-three-tiles ${
      styles['bb-two-three-tiles']
    } ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    id={uuid}
  >
    {heading && (
      <div className="container">
        <h2 className={styles['bb-two-three-tiles__heading']}>{heading}</h2>
      </div>
    )}
    <ul
      className={`${styles['bb-two-three-tiles__items']} ${styles[`bb-two-three-tiles__items--items-${items.length}`]}`}
    >
      {items.map((item, index) => (
        <li className={styles['bb-two-three-tiles__item']} key={item.title}>
          <Link {...item.button.nextLink}>
            <a
              href={item.button.nextLink.url}
              data-title={item.button.nextLink.title ?? item.title}
              data-category="Full width tile block"
              data-position={index + 1}
              data-variant={items.length}
              data-button-type={item.button.type}
              data-analytics="tile-item"
            >
              {item.image ? (
                <div className={styles['bb-two-three-tiles__item-image']}>
                  <DefaultPicture {...item.image} />
                </div>
              ) : (
                <div className={styles['bb-two-three-tiles__item-image-emergency']}></div>
              )}

              {item.showLabel && (
                <BrandedBadge
                  label="as seen on TV"
                  className={styles['bb-two-three-tiles__item-label']}
                />
              )}

              <div className={styles['bb-two-three-tiles__item-info']}>
                <div className={styles['bb-two-three-tiles__item-title']}>{item.title}</div>
                <Button tag="button" type={item.variant} size="large" withArrow tabIndex="-1">
                  {item.button.label}
                </Button>
              </div>
            </a>
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

BBTwoThreeTiles.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      variant: PropTypes.oneOf(['secondary', 'primary', 'emergency']).isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.shape(picturePropTypes),
      button: PropTypes.shape({
        label: PropTypes.string.isRequired,
        nextLink: PropTypes.shape(linkPropTypes).isRequired,
      }).isRequired,
      showLabel: PropTypes.bool,
    }),
  ).isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBTwoThreeTiles;
