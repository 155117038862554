import React from 'react';
import PropTypes from 'prop-types';
import HeroBackground from '../HeroBackground';
import { propTypes as defaultPicturePropTypes } from '../../../../01_atoms/DefaultPicture';
import HeroHomepageTaglineSVG from '../../../../../public/static/straplines/hero-homepage-tagline.svg';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBHeroHomepageBrandedTagline = ({
  imagePicture,
  videoURL = null,
  behaviorSettings = null,
  uuid = null,
}) => (
  <HeroBackground
    uuid={uuid}
    className={`bb-hero-homepage-branded-tagline ${
      styles['bb-hero-homepage-branded-tagline']
    } ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    imagePicture={imagePicture}
    videoURL={videoURL}
  >
    <div className="container-wrapper">
      <div className="container">
        <div className="bb-hero-homepage-branded-tagline--animated-tagline">
          <HeroHomepageTaglineSVG
            aria-label="Ending extreme poverty whatever it takes"
            role="img"
          />
        </div>
      </div>
    </div>
  </HeroBackground>
);

BBHeroHomepageBrandedTagline.propTypes = {
  imagePicture: PropTypes.shape(defaultPicturePropTypes).isRequired,
  videoURL: PropTypes.string,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBHeroHomepageBrandedTagline;
