import React from 'react';
import { EmptyImageWithFallbackForIE as EmptyImageWithFallback } from '../EmptyImage';

type Props = {
  media: string;
  url: string;
  className?: string;
};

const Picture = ({ className = '', media, url, ...props }: Props) => (
  <picture className={className}>
    <source srcSet={url} media={media} />
    <EmptyImageWithFallback {...props} />
  </picture>
);

export default Picture;
