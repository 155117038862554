import { string, number, shape, arrayOf } from 'prop-types';

export const PTHubSearchResult = {
  item: shape({
    _id: string,
    title: arrayOf(string),
    description: arrayOf(string),
    url: arrayOf(string),
    created: arrayOf(number),
    _score: number,
  }),
};
