import React from 'react';
import PropTypes from 'prop-types';

const FrayedContainer = ({ children, containerClass = '', title = '' }) => (
  <div className={`frayed-container ${containerClass}`}>
    <div className="container">
      {title && <h2>{title}</h2>}
      {children}
    </div>
  </div>
);

FrayedContainer.propTypes = {
  containerClass: PropTypes.string,
  title: PropTypes.string,
};

export default FrayedContainer;
