import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';

// Observes visibility of given DOM ids.
// Inspired by https://www.emgoto.com/react-table-of-contents/.
const useIntersectionObserver = (headingIds, setActiveId) => {
  const headingElementsRef = useRef({});
  useEffect(() => {
    const callback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);

      // Get all headings that are currently visible on the page
      const visibleHeadings = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      const getIndexFromId = (id) => headingIds.findIndex((item) => item === id);

      // If there is only one visible heading, this is our "active" heading
      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
        // If there is more than one visible heading,
        // choose the one that is closest to the top of the page
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id),
        );

        setActiveId(sortedVisibleHeadings[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: '-100px 0px -40% 0px',
    });

    headingIds.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        observer.observe(element);
      }
    });

    return () => observer.disconnect();
  }, [headingIds, setActiveId]);
};

const BBSidebarTableOfContents = ({ headings = [], behaviorSettings = null, uuid }) => {
  const headingIds = headings.map((item) => item.id);
  const [scrolledToId, setScrolledId] = useState();
  const [clickedId, setClickedId] = useState();
  const [realHeight, setRealHeight] = useState(0);
  const [isOverlappingBodyBlock, setOverlappingBodyBlock] = useState(false);
  useIntersectionObserver(headingIds, setScrolledId);

  useEffect(() => {
    // Selectors that match elements that are known to overlap with the Table of contents.
    // If HTML element has data-toc="hide" attribute, TOC will be hidden when it is nearby the element.
    const overlappedBodyBlocks = document.querySelectorAll('*[data-toc="hide"]');

    const scrollListener = () => {
      const tocElementPosition = document.getElementById(uuid).getBoundingClientRect();
      let isOverlapping = false;
      overlappedBodyBlocks.forEach((element) => {
        const elementPosition = element.getBoundingClientRect();
        if (
          elementPosition.top <= tocElementPosition.top + tocElementPosition.height &&
          elementPosition.top + elementPosition.height > tocElementPosition.top
        ) {
          isOverlapping = true;
        }
      });
      if (!isOverlappingBodyBlock && isOverlapping) {
        setOverlappingBodyBlock(true);
      }
      if (isOverlappingBodyBlock && !isOverlapping) {
        setOverlappingBodyBlock(false);
      }
    };

    if (overlappedBodyBlocks.length) {
      window.addEventListener('scroll', scrollListener);
    }
    return () => {
      if (overlappedBodyBlocks.length) {
        window.removeEventListener('scroll', scrollListener);
      }
    };
  }, [isOverlappingBodyBlock, uuid]);

  // Get real block height to fine tune sticky layout.
  useEffect(() => {
    const element = document.getElementById(`${uuid}-nav`);
    if (element) {
      setRealHeight(Math.ceil(element.getBoundingClientRect().height));
    }
  }, [uuid]);

  if (!headings.length) {
    return null;
  }

  const onClick = (e, id) => {
    e.preventDefault();
    const headerOffset = 80;
    const elementPosition = document.getElementById(id).getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });

    setClickedId(id);
    // Reset clicked id after scroll finished (approximately).
    setTimeout(() => {
      setClickedId(null);
    }, 1000);
  };

  // Respect clicked id over scrolled id to reduce changes of active heading
  // while the user is being scrolled to the clicked heading.
  const activeId = clickedId ? clickedId : scrolledToId;

  const classes = [
    'bb',
    styles['bb-sidebar-toc'],
    isOverlappingBodyBlock ? styles['bb-sidebar-toc-hidden'] : '',
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];

  return (
    <div className={classes.join(' ')} id={uuid} style={realHeight ? { height: 'auto' } : {}}>
      <nav
        id={`${uuid}-nav`}
        aria-label="Table of contents"
        style={realHeight ? { marginBottom: `-${realHeight + 40}px` } : {}}
      >
        <h4>Contents</h4>
        <ul>
          {headings.map((item) => (
            <li key={`sidebar_toc.${item.id}`}>
              <a
                tabIndex={0}
                className={`analytics-bb-sidebar-toc ${
                  activeId === item.id ? styles['active'] : ''
                }`}
                href={`#${item.id}`}
                onClick={(e) => onClick(e, item.id)}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

BBSidebarTableOfContents.propTypes = {
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ),
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string.isRequired,
};

export default BBSidebarTableOfContents;
