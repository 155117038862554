import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const CenteredColumn = ({ children }) => (
  <div className={`container centered-column-layout ${styles['centered-column-layout']}`}>
    <div className="wrapper">
      {Array.isArray(children) &&
        children.map((item, itemIndex) => (
          <React.Fragment key={itemIndex}>
            {/* eslint-disable-line */}
            {item}
          </React.Fragment>
        ))}

      {!Array.isArray(children) && <>{children}</>}
    </div>
  </div>
);

CenteredColumn.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default CenteredColumn;
