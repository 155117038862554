import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import keyboardFocusWithinElement from '../../../utils/keyboardFocusWithinElement';
import CloseIcon from '../../../public/static/icons/close-outline-white.svg';

import styles from './index.module.scss';

class Popup extends React.Component {
  constructor(props) {
    super(props);

    this.closeOnKeyPress = this.closeOnKeyPress.bind(this);
    this.closeByClickToBackground = this.closeByClickToBackground.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.autoFocusPopup = this.autoFocusPopup.bind(this);

    this.state = {
      docRoot: null,
    };
  }

  onKeyDownHandler(event) {
    const { isVisible = false } = this.props;
    const tab = 9;
    if (isVisible && event.keyCode === tab) {
      keyboardFocusWithinElement(event, '.popup:not(.d-none)', true);
    }
    // Close popup on Exit button.
    const exit = 27;
    if (isVisible && event.keyCode === exit) {
      this.props.onPopupClose();
    }
  }

  // Close if enter has been pressed.
  closeOnKeyPress(event) {
    const enter = 13;
    if (event.charCode === enter) {
      this.props.onPopupClose();
    }
  }

  // Close popup overlay when you click on any place on background, except interface elements.
  closeByClickToBackground(event) {
    if (
      event.target.className === 'container popup-container' ||
      event.target.className === 'popup-navigation'
    ) {
      this.props.onPopupClose();
    }
  }

  autoFocusPopup() {
    const { isVisible = false } = this.props;
    if (isVisible) {
      setTimeout(() => {
        /**
         * Focusing ".popup-navigation" works by the same ways as focusing ".popup",
         * but using this approach we avoid the problems on gifts website:
         * for some reason programming focus to ".popup" leaves header active,
         * over the popup layer.
         */
        const element = document.querySelector('.popup:not(.d-none) .popup-navigation');
        if (element) {
          element.focus();
        }
      }, 0);
    }
  }

  componentDidMount() {
    if (document) {
      this.autoFocusPopup();
      document.addEventListener('keydown', this.onKeyDownHandler);
      this.setState({ docRoot: document.getElementsByTagName('BODY')[0] });
    }
  }

  componentDidUpdate(prevProps) {
    const { isVisible = false } = this.props;

    if (!document) {
      return;
    }

    if (isVisible && isVisible !== prevProps.isVisible) {
      // only exists in styleguide app
      const nav = document.getElementsByTagName('NAV')[0];
      const main = document.getElementsByTagName('MAIN')[0];

      // blur these ...
      if (nav) {
        nav.classList.add('with-popup');
      }
      if (main) {
        main.classList.add('with-popup');
      }
      // ... and disable scrolling on the body
      document.body.classList.add('with-popup');
      this.autoFocusPopup();
    }

    if (!isVisible && isVisible !== prevProps.isVisible) {
      const nav = document.getElementsByTagName('NAV')[0];
      const main = document.getElementsByTagName('MAIN')[0];

      if (nav) {
        nav.classList.remove('with-popup');
      }
      if (main) {
        main.classList.remove('with-popup');
      }
      document.body.classList.remove('with-popup');
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownHandler);
  }

  render() {
    const { docRoot } = this.state;
    const { isVisible = false, onPopupClose, className = '' } = this.props;

    if (!docRoot) {
      return null;
    }

    const { children } = this.props;
    const PopupCore = (
      <div
        className={`popup ${styles.popup} ${className} ${isVisible ? '' : 'd-none'}`}
        onClick={this.closeByClickToBackground}
      >
        <div className="popup-navigation" tabIndex={-1}>
          <button
            className="close-icon"
            onKeyPress={this.closeOnKeyPress}
            onClick={onPopupClose}
            aria-label="Close overlay"
            tabIndex={0}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="container popup-container">{children}</div>
      </div>
    );

    return (
      <>
        {
          // Render the popup as a direct child of <body> so that we can
          // control and style it properly.
          ReactDOM.createPortal(PopupCore, docRoot)
        }
      </>
    );
  }
}

Popup.propTypes = {
  isVisible: PropTypes.bool,
  onPopupClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Popup;
