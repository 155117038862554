import React from 'react';
import PropTypes from 'prop-types';

import s from './index.module.scss';

const Tagline = ({
  label,
  color = 'teal',
  background = 'white',
  size = 'small',
  className = '',
  inverted = false,
}) => (
  <div
    className={`${s['tagline']} ${className} ${s[`tagline__text-${color}`]} ${s[`tagline__bg-${background}`]} ${s[`tagline__size-${size}`]} ${
      inverted ? s['tagline__inverted'] : ''
    }`}
  >
    <span className={s['tagline__label']}>{label}</span>
  </div>
);

Tagline.propTypes = {
  label: PropTypes.string.isRequired,
  background: PropTypes.oneOf(['white', 'green', undefined]),
  color: PropTypes.oneOf(['teal', 'white', undefined]),
  size: PropTypes.oneOf(['small', 'big', undefined]),
  className: PropTypes.string,
  inverted: PropTypes.bool,
};

export default Tagline;
