import { useState, useLayoutEffect } from 'react';

// We started using screen size more and more often.
// It is a good time to unify the approach across all use cases.
// The solution inspired by
// https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
function getScreenSize() {
  // In original solution it was
  // `const { innerWidth: width, innerHeight: height } = window;`
  // but we use `document.body.clientWidth` to make it work in Storybook.
  const { clientWidth: width } = document.body;
  // `document.body.clientHeight` is not working properly.
  const { innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useScreenSize() {
  const [screenSize, setScreenSize] = useState({
    width: undefined,
    height: undefined,
  });

  useLayoutEffect(() => {
    const handleResize = () => {
      setScreenSize(getScreenSize());
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return screenSize;
}
