import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

export const sortSelectedFilters = (selectedValues, currentOrder) => {
  const filters = [];
  const selectedFilters = [];

  Object.keys(selectedValues).forEach((component) => {
    const value = selectedValues[component];
    if (!value) return;
    if (component.startsWith('result')) return;

    if (component === 'search') {
      selectedFilters.push({
        component,
        value: `'${value}'`,
      });
    }

    if (Array.isArray(value)) {
      value.forEach((item) => {
        selectedFilters.push({
          component,
          value: item,
        });
      });
    }
  });

  currentOrder.forEach((item) => {
    let position = '';
    selectedFilters.forEach((filter, index) => {
      if (filter.value === item.value && filter.component === item.component) {
        position = index;
      }
    });

    if (position === +position) {
      filters.push(selectedFilters[position]);
      selectedFilters.splice(position, 1);
    }
  });

  return filters.concat(selectedFilters);
};

const SelectedFiltersComponent = (selectedFilters, toggleMobileFilterPopup, isHubHomePage) => {
  const SelectedFilters = (props) => {
    const { selectedValues, setValue, clearValues } = props;

    const clearFilterValue = (component, value) => {
      if (Array.isArray(selectedValues[component].value)) {
        const newValue = selectedValues[component].value.filter((item) => item !== value);

        if (newValue.length) {
          setValue(component, newValue);
        } else {
          setValue(component, null);
        }
      } else {
        setValue(component, null);
      }
    };

    const countFilters = selectedFilters.length;

    return (
      <div
        className={`${styles['selected-filters']}${
          isHubHomePage ? ` ${styles['hub-home-page']}` : ''
        }`}
      >
        <div
          className={`${styles['selected-filters-icon']} hide-when-no-results-mobile`}
          onClick={toggleMobileFilterPopup}
        >
          Filters
        </div>
        {!isHubHomePage &&
          selectedFilters.map((item) => (
            <div
              key={`${item.component}-${item.value}`}
              className={`${styles['selected-filter']} selected-filter-${item.value}-testsuite`}
            >
              {item.value}
              <a
                className={styles['remove-filter']}
                aria-label="Remove filter"
                onClick={(e) => {
                  e.preventDefault();
                  clearFilterValue(item.component, item.value);
                }}
                href="#"
              />
            </div>
          ))}
        {!isHubHomePage && countFilters > 1 && (
          <a
            className={`${styles['clear-all-filters']} clear-all-filters-testsuite`}
            onClick={(e) => {
              e.preventDefault();
              clearValues();
            }}
            href="#"
          >
            Clear
          </a>
        )}
      </div>
    );
  };

  SelectedFilters.propTypes = {
    selectedValues: PropTypes.shape.isRequired,
    setValue: PropTypes.func.isRequired,
    clearValues: PropTypes.func.isRequired,
  };

  return SelectedFilters;
};

export default SelectedFiltersComponent;
