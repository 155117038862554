import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MultiList, SelectedFilters } from '@appbaseio/reactivesearch';
import BBHubSearch from '../index';
import styles from './index.module.scss';
import Button from '../../../../01_atoms/Button';

const FilterList = ({
  data,
  onChange,
  selectedValues = null,
  sectionName = '',
  isLoading = true,
}) => {
  const [isExpanded, setExpanded] = useState(false);

  if (!data.length) {
    return null;
  }

  let filters = data;
  const countOfVisibleItems = 6;
  let showMoreButton = '';
  let hideButton = '';
  // Display 6 items and hide other.
  if (data.length > countOfVisibleItems && !isExpanded) {
    filters = data.slice(0, countOfVisibleItems);
    showMoreButton = `+ ${data.length - countOfVisibleItems} more`;
  } else if (data.length > countOfVisibleItems && isExpanded) {
    hideButton = `hide ${data.length - countOfVisibleItems} filters`;
  }

  return (
    <div className={styles['filter-section']}>
      <h2>{sectionName}</h2>
      {filters.map((item) => (
        <div
          className={`${styles['filter-checkbox']}${
            // eslint-disable-next-line no-prototype-builtins
            selectedValues && selectedValues.hasOwnProperty(item.key) ? ` ${styles['checked']}` : ''
          }`}
          key={`${item.key}-${item.doc_count}`}
        >
          <label
            tabIndex="0"
            htmlFor={`${sectionName}-${item.key}`}
            onKeyPress={() => document.getElementById(`${sectionName}-${item.key}`).click()}
          >
            <input
              id={`${sectionName}-${item.key}`}
              type="checkbox"
              value={item.key}
              onChange={onChange}
              onKeyPress={onChange}
              // eslint-disable-next-line no-prototype-builtins
              checked={selectedValues && selectedValues.hasOwnProperty(item.key)}
            />
            <span>
              {item.key} <span className={styles['item-count']}>({item.doc_count})</span>
            </span>
          </label>
        </div>
      ))}

      {isLoading && <div className={styles['loading-overlay']} />}

      {showMoreButton && (
        <div
          tabIndex="0"
          className={styles['show-more']}
          onClick={() => setExpanded(true)}
          onKeyPress={() => setExpanded(true)}
        >
          {showMoreButton}
        </div>
      )}
      {hideButton && (
        <div
          tabIndex="0"
          className={styles['show-more']}
          onClick={() => setExpanded(false)}
          onKeyPress={() => setExpanded(false)}
        >
          {hideButton}
        </div>
      )}
    </div>
  );
};

FilterList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      doc_count: PropTypes.number,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValues: PropTypes.object,
  sectionName: PropTypes.string,
  isLoading: PropTypes.bool,
};

const MultiListComponent = (sectionName) => {
  const MultiList = ({ data, handleChange, value, loading }) => {
    return (
      <FilterList
        data={data}
        onChange={handleChange}
        selectedValues={value}
        sectionName={sectionName}
        isLoading={!!loading}
      />
    );
  };

  MultiList.propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        doc_count: PropTypes.number,
      }),
    ).isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  return MultiList;
};

MultiListComponent.propTypes = {
  sectionName: PropTypes.string,
};

const FiltersComponent = ({
  isMobileFiltersOpened,
  toggleMobileFilterPopup,
  countSelectedFiltersWithoutSearch,
}) => {
  // Add syntactic Apply filters button when count of mobile filters changes.
  const [isFiltersChanged, setFiltersChanged] = useState(false);
  let prevIsMobileFiltersOpened = useRef();
  let prevCountSelectedFiltersWithoutSearch = useRef();

  useEffect(() => {
    if (prevIsMobileFiltersOpened.current && !isMobileFiltersOpened) {
      prevIsMobileFiltersOpened.current = isMobileFiltersOpened;
    }
  }, [isMobileFiltersOpened]);

  useEffect(() => {
    if (prevCountSelectedFiltersWithoutSearch.current !== countSelectedFiltersWithoutSearch) {
      prevCountSelectedFiltersWithoutSearch.current = countSelectedFiltersWithoutSearch;
    }
  }, [countSelectedFiltersWithoutSearch]);

  if (
    prevCountSelectedFiltersWithoutSearch.current !== countSelectedFiltersWithoutSearch &&
    isMobileFiltersOpened &&
    !isFiltersChanged
  ) {
    setFiltersChanged(true);
  }

  if (!isMobileFiltersOpened && isFiltersChanged) {
    setFiltersChanged(false);
  }

  return (
    <div
      className={`${styles['hub-search-filters']} hide-when-no-results${
        isMobileFiltersOpened ? ` ${styles['mobile-filters-opened']}` : ''
      }`}
      aria-haspopup="true"
      aria-expanded={isMobileFiltersOpened}
    >
      <div className={styles['mobile-buttons']}>
        <div className={styles['back-button']} onClick={toggleMobileFilterPopup}>
          Back
        </div>
        {!!countSelectedFiltersWithoutSearch && (
          <SelectedFilters
            render={({ clearValues }) => (
              <div className={styles['clear-button']} onClick={clearValues}>
                Clear filters
              </div>
            )}
          />
        )}
        {!!countSelectedFiltersWithoutSearch && isFiltersChanged && (
          <Button
            type="secondary"
            className={styles['syntactic-apply-filters-button']}
            onClick={toggleMobileFilterPopup}
          >
            Apply filters
          </Button>
        )}
      </div>
      <MultiList {...BBHubSearch.components.regions} />
      <MultiList {...BBHubSearch.components.countries} />
      <MultiList {...BBHubSearch.components.themes_sectors} />
      <MultiList {...BBHubSearch.components.approaches} />
      <MultiList {...BBHubSearch.components.languages} />
      <MultiList {...BBHubSearch.components.programmes} />
      <MultiList {...BBHubSearch.components.types} />
      <MultiList {...BBHubSearch.components.partners_networks} />
    </div>
  );
};

FiltersComponent.propTypes = {
  isMobileFiltersOpened: PropTypes.bool.isRequired,
  toggleMobileFilterPopup: PropTypes.func.isRequired,
  countSelectedFiltersWithoutSearch: PropTypes.number.isRequired,
};

export { MultiListComponent };
export default FiltersComponent;
