import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import withCookieConsentPopupVideo from './withCookieConsentPopupVideo';
import Popup from '../Popup';

import styles from './index.module.scss';

const Player = dynamic(() => import('react-player'));
const VideoPlayerWithCookieConsent = withCookieConsentPopupVideo((props) => <Player {...props} />);

const YoutubeVideoInPopup = ({
  isVisible = false,
  onPopupClose,
  videoProps,
  acceptCookiesButtonClassName = '',
}) => {
  if (!isVisible) {
    return null;
  }
  return (
    <Popup isVisible onPopupClose={onPopupClose} className={styles['youtube-video-in-popup']}>
      {/* Automatically load the video because popup is opened */}
      <VideoPlayerWithCookieConsent
        {...videoProps}
        playing
        width="1024px"
        height="66vmin"
        url={videoProps.videoURL}
        style={{ background: 'none' }}
        onCancelDialog={onPopupClose}
        acceptCookiesButtonClassName={acceptCookiesButtonClassName}
      />
    </Popup>
  );
};

YoutubeVideoInPopup.propTypes = {
  isVisible: PropTypes.bool,
  onPopupClose: PropTypes.func.isRequired,
  videoProps: PropTypes.object.isRequired,
  acceptCookiesButtonClassName: PropTypes.string,
};

export default YoutubeVideoInPopup;
