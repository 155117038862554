import React from 'react';
import PropTypes from 'prop-types';

import FormattedText from '../../../../01_atoms/FormattedText';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

import styles from './index.module.scss';
import baseStyles from '../index.module.scss';

const BBHeroHubHomePage = ({ title, description, behaviorSettings = null, uuid = null }) => (
  <div
    className={`bb bb-hero bb-hero-hub-home-page ${baseStyles['bb-hero']} ${
      styles['bb-hero-hub-home-page']
    } ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    id={uuid}
  >
    <div className="hub-home-page-background-image">
      <div className="hub-home-page-background-image__wrapper"></div>
    </div>

    <div className="container-wrapper">
      <div className="container">
        <div className="row">
          <div className="overlay col-12 col-lg-8 col-xl-8">
            {/* We use empty title to overwrite title for the block. */}
            <h1 className="" title="">
              {title}
            </h1>

            {/* We use empty title to overwrite title for the block. */}
            <div title="">
              {description && <FormattedText className="description" text={description} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

BBHeroHubHomePage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBHeroHubHomePage;
