import React from 'react';

// CNET styles.
import '../../components/01_atoms/FormElements/CheckboxesField/_style.scss';
// import '../../components/01_atoms/FormElements/FieldError/_style.scss';
// import '../../components/01_atoms/FormElements/FieldTemplate/_style.scss';
// import '../../components/01_atoms/FormElements/RadiosField/_style.scss';
// import '../../components/01_atoms/FormElements/RadiosButton/_style.scss';
import '../../components/01_atoms/FormElements/SelectField/_style.scss';
import '../../components/01_atoms/FormElements/SelectField/SelectFieldStandalone/_style.scss';
import '../../components/01_atoms/FormElements/TextField/_style.scss';
import '../../components/01_atoms/FormElements/TextFieldPill/_style.scss';
import '../../components/01_atoms/FormElements/ToggleGroupField/_style.scss';
import styles from './index.module.scss';

const Form = ({ children }) => <div className={styles['form']}>{children}</div>;

export default Form;
