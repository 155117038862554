import React from 'react';
import Head from 'next/head';
import DonationImpactIcon from '../../../public/static/icons/donation-impact.svg';

import s from './index.module.scss';

type Props = {
  text: string;
  image?: {
    url: string;
    alt: string;
  };
  className?: string;
  styling?: 'default' | 'emergency';
};

const ImpactText = ({ text, image, className = '', styling = 'default' }: Props) => (
  <div
    className={`${s['impact-text']} ${s[`impact-text--${styling}`]} ${className}`}
    data-container="impact-text"
  >
    <div className={s['impact-text__message']} data-component="impact-text">
      {image && (
        <div
          className={s['impact-text__image']}
          title={image.alt}
          style={{ backgroundImage: `url(${image.url})` }}
        >
          <Head>
            <link rel="preload" href={image.url} as="image" />
          </Head>
        </div>
      )}
      {!image && <DonationImpactIcon className={s['impact-text__icon']} />}
      <div className={s['impact-text__text']}>{text}</div>
    </div>
  </div>
);

export default ImpactText;
