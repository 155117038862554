import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../../../01_atoms/Link';
import Button from '../../../../01_atoms/Button';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBInlineCTA = ({
  title = '',
  alignment = 'left',
  links = [],
  behaviorSettings = null,
  uuid = null,
}) => {
  const componentName = 'bb-inline-cta';
  const classes = [
    'bb',
    styles[componentName],
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];
  if (styles[componentName + '--align-' + alignment] !== undefined) {
    classes.push(styles[componentName + '--align-' + alignment]);
  }
  return (
    <div
      className={classes.join(' ')}
      data-component={componentName}
      data-alignment={alignment}
      id={uuid}
    >
      <div className="container">
        {title && (
          <p>
            <strong>{title}</strong>
          </p>
        )}

        <div className="inline-buttons">
          {links.map((link) => (
            <Link {...link.nextLink} key={link.title}>
              <Button
                tag="a"
                href={link.nextLink.url}
                isMobileBlock
                type={link.type}
                size={link.size}
                isOutlined={link.isOutlined}
                className={`${componentName}__button`}
              >
                {link.title}
              </Button>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

BBInlineCTA.propTypes = {
  title: PropTypes.string,
  alignment: PropTypes.oneOf(['left', 'center']),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      nextLink: PropTypes.shape(linkPropTypes).isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['primary', 'secondary', 'emergency', 'white-pink', 'white-green']),
      size: PropTypes.oneOf(['small', 'large']),
      isOutlined: PropTypes.bool,
    }),
  ),
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBInlineCTA;
