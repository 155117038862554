import React, { useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import DefaultPicture, {
  propTypes as defaultPicturePropTypes,
} from '../../../../../01_atoms/DefaultPicture';
import Link, { propTypes as linkPropTypes } from '../../../../../01_atoms/Link';
import LogoWhite from '../../../../../../public/static/icons/logos/logo-white.svg';

import s from './index.module.scss';
import useScreenSize from '../../../../../../hooks/useScreenSize';

const HeroSocialAdWrapper = ({
  logoLink,
  heading,
  image,
  uuid = null,
  classnames = '',
  contentLeft = null,
  contentRight = null,
  contentBottom = null,
  variant = 'default',
}) => {
  const { width } = useScreenSize();
  const [topSectionHeight, setTopSectionHeight] = useState(0);
  const ref = useRef(null);

  useLayoutEffect(() => {
    const handleTopSectionHeight = () => {
      if (topSectionHeight !== ref.current.clientHeight) {
        document.fonts.ready.then(() => {
          setTopSectionHeight(ref.current.clientHeight);
        });
      }
    };

    window.addEventListener('resize', handleTopSectionHeight);
    handleTopSectionHeight();

    return () => {
      window.removeEventListener('resize', handleTopSectionHeight);
    };
  }, []);

  const isDesktop = width >= 1200;
  const secondInfoMargin = !isDesktop || topSectionHeight > 542 ? 0 : 542 - (topSectionHeight + 40);

  return (
    <div
      id={uuid}
      className={`${classnames} ${s['hero-social-ad-wrapper']} ${s[`hero-social-ad-wrapper--variant-${variant}`]}`}
    >
      <div className={s['hero-social-ad-wrapper__top-section']} ref={ref}>
        <div className="container">
          {logoLink && (
            <div className={s['hero-social-ad-wrapper__site-logo']}>
              <Link {...logoLink}>
                <a tabIndex="0">
                  <LogoWhite
                    alt="Concern Worldwide"
                    className={s['hero-social-ad-wrapper__logo-image']}
                    aria-label="Concern Worldwide"
                  />
                </a>
              </Link>
            </div>
          )}
          <div className={s['hero-social-ad-wrapper__content']}>
            <h1 className={s['hero-social-ad-wrapper__title']}>{heading}</h1>
            <div className={s['hero-social-ad-wrapper__image']}>
              <DefaultPicture {...image} />
            </div>
          </div>
        </div>
      </div>
      <div className={s['hero-social-ad-wrapper__bottom-section']}>
        <div className="container">
          <div className={s['hero-social-ad-wrapper__image-placeholder']} />
          <div className={s['hero-social-ad-wrapper__content']}>
            <div className={s['hero-social-ad-wrapper__first-info']}>{contentLeft}</div>
            <div
              className={s['hero-social-ad-wrapper__second-info']}
              style={{ marginTop: secondInfoMargin }}
            >
              {contentRight}
            </div>
            {contentBottom}
          </div>
        </div>
      </div>
    </div>
  );
};

HeroSocialAdWrapper.propTypes = {
  logoLink: PropTypes.shape(linkPropTypes).isRequired,
  heading: PropTypes.string.isRequired,
  image: PropTypes.shape(defaultPicturePropTypes).isRequired,
  uuid: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'emergency']),
  classnames: PropTypes.string,
  contentLeft: PropTypes.object,
  contentRight: PropTypes.object,
  contentBottom: PropTypes.object,
};

export default HeroSocialAdWrapper;
